import React from "react"
import PropTypes from "prop-types"

import Event from "./Event"

import "./End.scss"

class End extends React.Component {
  render() {
    let title = `Die Überraschung von ${this.props.about.for} ist vorbei`
    let text = `Die Überraschung von ${this.props.about.for} ist leider schon vorbei - wir hoffen, es hat viel Spass gemacht.`
    return (
      <Event event={this.props.event} className="event-end" title={title} onReaction={this.props.onReaction} audience={this.props.audience}>
        <div className="end-text">
          {text}
        </div>
      </Event>
    )
  }
}

End.propTypes = {
  about: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onReaction: PropTypes.func.isRequired,
  audience:PropTypes.oneOf(["adventurer", "watcher"]).isRequired
}

End.defaultProps = {
  audience:"adventurer"
}

export default End