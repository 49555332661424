import React from "react"
import PropTypes from "prop-types"

import Event from "./Event"

import "./Activity.scss"

class Activity extends React.Component {
  render() {
    let title = `${this.props.event.title}`
    let description = this.props.event.description.split('/n').map((line, index) => {
      return (
        <p key={index}>{line}</p>
      )
    })
    let more = null
    if(this.props.audience !== "adventurer") {
      more = (
        <div className="activity-more">
          <a href={`https://appentura.ch/shop/activity/${this.props.event.slug}`} target="_blank" rel="noopener noreferrer">mehr Informationen ...</a>
        </div>
      )
    }
    return (
      <Event event={this.props.event} className="event-activity" title={title} onReaction={this.props.onReaction} audience={this.props.audience}>
        <div className="activity-image-and-description">
          <div className="activity-image">
            <img src={this.props.event.image} alt={this.props.event.title} />
          </div>
          <div className="activity-description">
            {description}
          </div>
        </div>
        {more}
      </Event>
    )
  }
}

Activity.propTypes = {
  about: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onReaction: PropTypes.func.isRequired,
  audience:PropTypes.oneOf(["adventurer", "watcher"]).isRequired
}

Activity.defaultProps = {
  audience:"adventurer"
}

export default Activity
