import AvailableAreas from "./AvailableAreas"
import AvailableTimes from "./AvailableTimes"
import Companion from "./Companion"
import Intro from "./Intro"
import Location from "./Location"
import Name from "./Name"
import StartDate from "./StartDate"
import StartTime from "./StartTime"
import Transport from "./Transport"

export default {
  AvailableTimes,
  AvailableAreas,
  Companion,
  Intro,
  Location,
  Name, 
  StartDate,
  StartTime,
  Transport
}