

const config = {
  development: {
    root:`http://${window.location.hostname}:8090/v1`,
    rootBuddy:`http://${window.location.hostname}:8090/buddy`,
  },
  staging: {
    root:"https://api-staging.appentura.io/v1",
    rootBuddy:"https://api-staging.appentura.io/buddy"
  },
  production: {
    root:"https://api.appentura.io/v1",
    rootBuddy:"https://api.appentura.io/buddy"
  }
};

export default config