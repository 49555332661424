import React from "react"
import PropTypes from "prop-types"

import Modal from "./Modal"

import "./ModalHelp.scss"

class ModalHelp extends React.Component {
  constructor(props) {
    super(props)
    this.onClickClose = this.onClickClose.bind(this)
  }

  render() {
    return (
      <Modal visible={true} title="Hilfe" onClose={this.onClickClose}>
        <div id="modal-help" className="block">
          <div className="block-inner">
            <div className="explanation">
              Brauchst du Hilfe? Ruf uns einfach an.
            </div>

            <div className="console">
              <button className="green" onClick={this.onClickClose}>schliessen</button>
            </div>
          </div>
        </div>

      </Modal>
    )
  }

  onClickClose(e) {
    if(e.preventDefault) {
      e.preventDefault()
    }
    this.props.onClose()
  }
}

ModalHelp.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default ModalHelp
