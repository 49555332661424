import React from "react"

import "./Loading.scss"

class Loading extends React.Component {
  render() {
    return (
      <div className="c-loading">
      </div>
    )
  }
}

export default Loading