import React from "react"
import PropTypes from "prop-types"
import { initializeApp } from "firebase/app"
import { getDatabase, ref, onValue, onChildAdded, onChildRemoved, onChildChanged } from "firebase/database"
//import firebase from 'firebase'
import superagent from 'superagent'
import {Helmet} from 'react-helmet'
import config from './../config'
import devlog from '../utils/devlog'

// components
import Block from "./Block"
import ModalShare from './ModalShare'
import Loading from './Loading'
import Error from "./Error";
import Countdown from "./../components/Countdown"
import Header from "./Timeline.Header";
import {Activity, Announcement, End, Message, Review, Selfie, Start, Travel} from "./timeline-events"

// styling and images
import "./Timeline.scss"

// TODO remove all unused propTypes

// initialize firebase
const firebaseApp = initializeApp(config.firebase);
/*
if(!firebase.apps.length) {
  firebase.initializeApp(config.firebase)
}
*/

class Timeline extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasData:false,
      isPosting:false, // while uploading images for instance
      refExists:true, // we are optimistic
      modal_action:null
    }

    this.firebase_ref = null
    this.firebase_ref_events = null
    this.firebase_onValue_processed = false

    // bind methods
    this.readyFirebase = this.readyFirebase.bind(this)
    this.onChangeMessageText = this.onChangeMessageText.bind(this)
    this.onSubmitMessage = this.onSubmitMessage.bind(this)
    this.onDeleteMessage = this.onDeleteMessage.bind(this)
    this.onDeleteSelfie = this.onDeleteSelfie.bind(this)
    this.onReaction = this.onReaction.bind(this)


    /*
    this.onModalSelfieCancel = this.onModalSelfieCancel.bind(this)
    this.onModalSelfieAccept = this.onModalSelfieAccept.bind(this)
    this.onModalErrorClose = this.onModalErrorClose.bind(this)
    */



    this.onCountdownOver = this.onCountdownOver.bind(this)
  }

  componentDidMount() {
    if(this.props.liveId) {
      this.readyFirebase(this.props.liveId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.liveId !== this.props.liveId) {
      this.readyFirebase(nextProps.liveId)
    }
  }

  readyFirebase(liveId) {
    const db = getDatabase(firebaseApp);
    
    this.firebase_ref = ref(db, `surprise/${liveId}`); 
    this.firebase_ref_events = ref(db, `surprise/${liveId}/events`);
    this.firebase_ref_about = ref(db, `surprise/${liveId}/about`);
    // this.firebase_ref = firebase.database().ref(`surprise/${liveId}`)
    // this.firebase_ref_events = firebase.database().ref(`surprise/${liveId}/events`)
    // this.firebase_ref_about = firebase.database().ref(`surprise/${liveId}/about`)

    onValue(this.firebase_ref, snapshot => {
      devlog("firebase_ref.once('value')")
      let exists = snapshot.val() !== null // checks if that ref actually exists
      if(exists) {
        let data = snapshot.val()
        // about
        let about = data.about
        // timeline-events
        let events = []
        for(let key in data.events) {
          let e = data.events[key]
          e.when = new Date(e.when)
          e.id = key
          events.push(e)
        }
        events = events.sort((a,b) => {
          return a.when > b.when ? -1 : 1
        })

        devlog('Timeline.js: Intial data received. about:', about, 'events:', events)

        // set the flag (indicating that we processed initial data)
        this.firebase_onValue_processed = true

        this.setState({...this.state, hasData:true, refExists:true, about, events})
      }
      else {
        this.setState({...this.state, hasData:true, refExists:false, about:null, events:null})
      }
    }, {onlyOnce:true});

    /*
    this.firebase_ref.once('value', (snapshot) => {
      devlog("firebase_ref.once('value')")
      let exists = snapshot.val() !== null // checks if that ref actually exists
      if(exists) {
        let data = snapshot.val()
        // about
        let about = data.about
        // timeline-events
        let events = []
        for(let key in data.events) {
          let e = data.events[key]
          e.when = new Date(e.when)
          e.id = key
          events.push(e)
        }
        events = events.sort((a,b) => {
          return a.when > b.when ? -1 : 1
        })

        devlog('Timeline.js: Intial data received. about:', about, 'events:', events)

        // set the flag (indicating that we processed initial data)
        this.firebase_onValue_processed = true

        this.setState({...this.state, hasData:true, refExists:true, about, events})
      }
      else {
        this.setState({...this.state, hasData:true, refExists:false, about:null, events:null})
      }

    })
    */

    // This event will be triggered once for each initial child at this location, and it will be triggered again every time a new child is added
    onChildAdded(this.firebase_ref_events, snapshot => {
      devlog("firebase_ref_events.on('child_added'")
      // because this event is also triggered on initial load we skip adding it here as long as we did not process the 'value' event of firebase_ref
      if(this.firebase_onValue_processed) {
        let event_to_add = snapshot.val()

        if(event_to_add !== null) {
          let events = this.state.events
          event_to_add.when = new Date(event_to_add.when)
          event_to_add.id = snapshot.key
          events.unshift(event_to_add)
          events = events.sort((a,b) => {
            return a.when > b.when ? -1 : 1
          })

          this.setState({...this.state, events})

          if(this.props.onEventAdded && event_to_add.what !== 'step') {
            this.props.onEventAdded()
          }
        }
      }
    });
    /*
    // This event will be triggered once for each initial child at this location, and it will be triggered again every time a new child is added
    this.firebase_ref_events.on('child_added', (snapshot) => {
      devlog("firebase_ref_events.on('child_added'")
      // because this event is also triggered on initial load we skip adding it here as long as we did not process the 'value' event of firebase_ref
      if(this.firebase_onValue_processed) {
        let event_to_add = snapshot.val()

        if(event_to_add !== null) {
          let events = this.state.events
          event_to_add.when = new Date(event_to_add.when)
          event_to_add.id = snapshot.key
          events.unshift(event_to_add)
          events = events.sort((a,b) => {
            return a.when > b.when ? -1 : 1
          })

          this.setState({...this.state, events})

          if(this.props.onEventAdded && event_to_add.what !== 'step') {
            this.props.onEventAdded()
          }
        }
      }
    })
    */

    // This event will be triggered once every time a child is removed.
    onChildRemoved(this.firebase_ref_events, snapshot => {
      devlog("firebase_ref_events.on('child_removed')")
      if(this.firebase_onValue_processed) {
        let event_to_remove = snapshot.val()
        if(event_to_remove !== null) {
          // NOTE: instead of removing we could flag it and thus make it possible to animate it 'vanishing'
          let events = this.state.events.filter(event => event.id !== snapshot.key)

          this.setState({...this.state, events})
        }
      }
    });

    /*
    // This event will be triggered once every time a child is removed.
    this.firebase_ref_events.on('child_removed', (snapshot) => {
      devlog("firebase_ref_events.on('child_removed')")
      if(this.firebase_onValue_processed) {
        let event_to_remove = snapshot.val()
        if(event_to_remove !== null) {
          // NOTE: instead of removing we could flag it and thus make it possible to animate it 'vanishing'
          let events = this.state.events.filter(event => event.id !== snapshot.key)

          this.setState({...this.state, events})
        }
      }
    })
    */

    // This event will be triggered when the data stored in 'events' (or any of its descendants) changes
    // - when reactions (smileys) are clicked
    onChildChanged(this.firebase_ref_events, snapshot => {
      devlog("firebase_ref_events.on('child_changed')")
      if(this.firebase_onValue_processed) {
        let updated_event = snapshot.val()
        if(updated_event !== null) {
          let events = this.state.events
          let event_to_update = events.find(e => e.id === snapshot.key)
          updated_event.when = new Date(updated_event.when)
          if(event_to_update) {
            Object.assign(event_to_update, updated_event)
          }
          this.setState({...this.state, events})
        }
      }
    });

    /*
    // This event will be triggered when the data stored in 'events' (or any of its descendants) changes
    // - when reactions (smileys) are clicked
    this.firebase_ref_events.on('child_changed', (snapshot) => {
      devlog("firebase_ref_events.on('child_changed')")
      if(this.firebase_onValue_processed) {
        let updated_event = snapshot.val()
        if(updated_event !== null) {
          let events = this.state.events
          let event_to_update = events.find(e => e.id === snapshot.key)
          updated_event.when = new Date(updated_event.when)
          if(event_to_update) {
            Object.assign(event_to_update, updated_event)
          }
          this.setState({...this.state, events})
        }
      }
    })
    */

    // This event will be triggered when the data stored in 'about' (or any of its descendants) changes
    onValue(this.firebase_ref_about, snapshot => {
      devlog("firebase_ref_about.on('value')")
      if(this.firebase_onValue_processed) {
        let updated_about = snapshot.val()
        if(updated_about !== null) {
          this.setState({...this.state, about:updated_about})
        }
      }
    });

    /*
    // This event will be triggered when the data stored in 'about' (or any of its descendants) changes
    this.firebase_ref_about.on('value', (snapshot) => {
      devlog("firebase_ref_about.on('value')")
      if(this.firebase_onValue_processed) {
        let updated_about = snapshot.val()
        if(updated_about !== null) {
          this.setState({...this.state, about:updated_about})
        }
      }
    })
    */
   
  }

  render() {
    let hasData = this.state.hasData
    let content = null
    if(hasData) {
      if(this.state.refExists) {
        content = (
          <div id="timeline" key="timeline">
            {this.render_helmet()}
            {/*this.render_header()*/}
            <Header 
              audience={this.props.audience} 
              adventurer={this.state.about.for}
              onClickShare={this.onShare_click}  
              onSubmitMessage={this.onSubmitMessage}
            />
            <Block key="timeline">
              {this.render_posting()}
              {this.render_countdown()}
              {this.render_events()}
            </Block>
          </div>
        )
      }
      else {
        // TODO #content #visual define text and visual design
        content = (
          <Error
            key="error"
            title="Hmmm ... dafür kann ich leider nichts finden"
            message={`Ich kann keine Daten für die angegebene LiveId '${this.props.liveId}' finden ...`}
          />
        )
      }
    }
    else {
      // TODO #visual
      content = (
        <Block key="loading">
          <Loading/>
        </Block>
      )
    }

    let modal_share = this.render_modal_share()

    return [content, modal_share]

  }

  /*
  render_header() {
    let header
    switch(this.props.audience) {
      case "adventurer":
        header = (
          <Block>
            <div className="header-adventurer">
              <div className="header-adventurer-first">
                <span>Teile diese Seite mit deinen Freunden</span>
                <button onClick={this.onShare_click} className="share">Teilen</button>
              </div>
            </div>
            <div className="header-adventurer">
              <div className="header-adventurer-second">
                <textarea placeholder="schreibe einen Eintrag" rows={1} value={this.state.message_text} onChange={this.onChangeMessageText}></textarea>
                <div className={`commentButton ${this.state.message_send_enabled ? '' : 'disabled'}`} onClick={this.onSubmitMessage}>
                  <button>Eintragen</button>
                </div>
              </div>
            </div>
          </Block>
        )
        break
      case "watcher":
        header = (
          <div className="header-watcher">
            <div className="header-watcher-title">
              Überraschungs-Erlebnis von {this.state.about.for}
            </div>
            <div className="header-watcher-pulse"><div className="header-watcher-pulse-inner"></div></div>
            <div className="header-watcher-explanation">
              Beobachte was {this.state.about.for} während der Überraschung erleben darf
            </div>

          </div>
        )
        break
      default:
        header = null
        break
    }


    return header
  }
  */

  render_posting() {
    let isPosting = null
    if(this.state.isPosting) {
      isPosting = (
        <div className="is-posting">
          <Loading/>
          Bild wird hochgeladen
        </div>
      )
    }
    return isPosting
  }
  render_countdown() {
    // the countdown is only rendered if
    // - the user is a watcher
    // - no events haven been posted yet
    // - the start time has not yet been reached
    let is_watcher = this.props.audience.toLowerCase() === "watcher"
    let has_no_events = this.state.events.length === 0
    let is_not_yet_time = new Date(this.state.about.start) > new Date()
    if(is_watcher && has_no_events && is_not_yet_time) {
      return (
        <div className="timeline-countdown">
          <div className="explanation">
            {`Bald startet die Überrschung von ${this.state.about.for}. Hier kannst du das Überraschungs-Erlebnis live mitverfolgen.`}
          </div>
          <Countdown onCountdownOver={this.onCountdownOver} time={this.state.about.start}/>
        </div>
      )
    }
    else {
      return null
    }
  }

  render_events() {
    let events = this.state.events.map((e, index) => {
      let content
      switch(e.what) {
        case 'travel':
          content = <Travel event={e} about={this.state.about} key={e.id} onReaction={this.onReaction} audience={this.props.audience} />
          break
        case 'message':
          content = <Message event={e} about={this.state.about} key={e.id} onDelete={this.onDeleteMessage} onReaction={this.onReaction} audience={this.props.audience} />
          break
        case 'announcement':
          content = <Announcement event={e} about={this.state.about} key={e.id} onReaction={this.onReaction} audience={this.props.audience} />
          break
        case 'review':
          content = <Review event={e} about={this.state.about} key={e.id} onReaction={this.onReaction} audience={this.props.audience} />
          break
        case 'activity':
          content = <Activity event={e} about={this.state.about} key={e.id} onReaction={this.onReaction} audience={this.props.audience} />
          break
        case 'selfie':
          content = <Selfie event={e} about={this.state.about} key={e.id} onDelete={this.onDeleteSelfie} onReaction={this.onReaction} audience={this.props.audience} />
          break
        case 'start':
          content = <Start event={e} about={this.state.about} onReaction={this.onReaction} key={e.id} audience={this.props.audience} />
          break
        case 'end':
          content = <End event={e} about={this.state.about} onReaction={this.onReaction} key={e.id} audience={this.props.audience} />
          break
        default:
          content = null
          break;
      }

      return content
    })

    return (
      <div id="timeline-events">
        {events}
      </div>
    )
  }

  render_helmet() {
    // let url = `${config.site.ticker}/${this.props.liveId}`
    return (
      <Helmet key="helmet">
        <title>{`Appentura Überraschung von ${this.state.about.for}`}</title>
        <meta property="og:url" content="https://www.appentura.ch" />
        <meta property="og:title" content={`Appentura Überraschung von ${this.state.about.for}`} />
        <meta property="og:description" content={`Sei live dabei und verfolge die unvergessliche Appentura Überraschung von ${this.state.about.for}`} />
        <meta property="og:image" content="https://www.appentura.ch/wp-content/uploads/2015/09/appentura_invertiert.png" />
      </Helmet>
    )
  }

  render_modal_share() {
    if(this.props.surprise) {
      let liveId = this.props.surprise.LiveId || 'unknown' // TODO 'unknown' ... not the clearest way to handle this
      if(this.state.modal_action === 'share') {
        return (
          <ModalShare onClose={this.onShare_close}
                      liveId={liveId}
                      key="modal_share"
                      reserverEmail={this.props.surprise.ReserverEmail}
                      reserverName={this.props.surprise.ReserverName} />
        )
      }
    }
    return null
  }

  onChangeMessageText(e) {
    let message_text = (e.target.value || '')
    let message_send_enabled = message_text.trim().length > 0
    this.setState({...this.state, message_text, message_send_enabled})
  }

  onSubmitMessage(message) {
    message = (message || "").trim();
    if(message.length > 0) {
      let data = {
        what:"message",
        text:message
      }
      this.postEvent(this.props.liveId, data)
      this.setState({...this.state, message_text:''})
    }
  }

  /*
  onSubmitMessage() {
    let message = (this.state.message_text || '').trim()
    if(message.length > 0) {
      let data = {
        what:"message",
        text:message
      }
      this.postEvent(this.props.liveId, data)
      this.setState({...this.state, message_text:''})
    }
  }
  */

  onDeleteMessage(eventId) {
    this.deleteEvent(this.props.liveId, eventId)
  }

  onDeleteSelfie(eventId) {
    this.deleteEvent(this.props.liveId, eventId)
  }

  onReaction(eventId, name) {
    let data = {
      what:"reaction",
      eventId:eventId,
      name:name
    }
    this.postEvent(this.props.liveId, data)
  }

  onShare_click = (e) => {
    e.stopPropagation()
    this.setState({...this.state, modal_action:'share'})
  }


  onShare_close = () => {
    this.setState({...this.state, modal_action:null})
  }

  onCountdownOver() {
    this.forceUpdate()
  }

  postEvent(liveId, data) {
    superagent
      .post(`${config.api.root}/surprise/ticker/${liveId}`)
      .send(data)
      .end((err, res) => {
        if(err) {
          // TODO error handling
        }
        else {
          // TODO show a Toast or something
        }

      })
  }

  deleteEvent(liveId, eventId) {
    let data = {
      what:"message",
      id:eventId
    }
    superagent
      .del(`${config.api.root}/surprise/ticker/${liveId}`)
      .send(data)
      .end((err, res) => {
        if(err) {
          // TODO error handling
        }
        else {
          //
        }

      })
  }

}

Timeline.propTypes = {
  surprise:PropTypes.object, // we only get this if the Timeline is inside the Ready View
  liveId:PropTypes.string.isRequired,
  audience:PropTypes.oneOf(["adventurer", "watcher"]).isRequired,
  onEventAdded:PropTypes.func
}

Timeline.defaultProps = {
  audience:"adventurer"
}

export default Timeline
