import React from "react"
import PropTypes from "prop-types"

import Event from "./Event"

import "./Start.scss"

class Start extends React.Component {
  render() {
    let title = `Die Überraschung von ${this.props.about.for} hat begonnen`
    let text = `${this.props.about.for} hat die Überraschung gestartet. Was wohl alles passieren wird? Die Spannung steigt!`
    return (
      <Event event={this.props.event} className="event-start" title={title} onReaction={this.props.onReaction} audience={this.props.audience}>
        <div className="start-text">
          {text}
        </div>
      </Event>
    )
  }
}

Start.propTypes = {
  about: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onReaction: PropTypes.func.isRequired,
  audience:PropTypes.oneOf(["adventurer", "watcher"]).isRequired
}

Start.defaultProps = {
  audience:"adventurer"
}
export default Start