import React from 'react';
import ReactDOM from 'react-dom';
import {unregister as unregisterServiceWorker} from './registerServiceWorker'
//import registerServiceWorker from './registerServiceWorker';


import 'semantic-ui-css/semantic.min.css';
import 'flatpickr/dist/flatpickr.min.css'
import './index.scss';
import './index-normalize.scss'

import App from './App';

console.log('Appentura - Überraschungserlebnisse der neuen Dimension')

ReactDOM.render(<App />, document.getElementById('root'));
unregisterServiceWorker() // unregister service worker to prevent page caching
//registerServiceWorker();

