import React, {useState, useEffect} from 'react'
import superagent from 'superagent'
import config from './../../config'

import "./RedeemMessage.scss"

function RedeemMessage({isHomeDelivery, isOnlineEvent}) {
  // state
  const [redeemMessage, setRedeemMessage] = useState(null)

  // mount
  useEffect(() => {
    const load = async() => {
      try {
        let key = "REDEEM_MESSAGE";
        if(isHomeDelivery) {
          key = "REDEEM_MESSAGE_HOMEDELIVERY";
        }
        else if(isOnlineEvent) {
          key = "REDEEM_MESSAGE_ONLINEEVENT";
        }
        let result = await superagent.get(`${config.api.root}/surpriseproperties/${key}`)
        let redeemMessage = result.body.value || {show:false}
        setRedeemMessage(redeemMessage)
      }
      catch(err) {
        console.error(err)
      }
    }
    load()
  }, [])

  // load message
  

  // render
  if(!redeemMessage || !redeemMessage.show) {
    return null
  }
  else {
    let messageLines = (redeemMessage.message || "").split("\n").map((line, index) => {
      return <p key={index}>{line}</p>
    })
    return (
      <div id="surprise-redeem-message">
        <div className="title">{redeemMessage.title}</div>
        <div className="message">{messageLines}</div>
      </div>
    )
  }
}

export default RedeemMessage