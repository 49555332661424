const config = {
  development: {
    HEAVY_WORKLOAD:false,
    DAYS_TO_PREPARE:4 // regular 4
  },
  staging: {
    HEAVY_WORKLOAD:false,
    DAYS_TO_PREPARE:4
  },
  production: {
    HEAVY_WORKLOAD:false,
    DAYS_TO_PREPARE:4
  }
}

// DAYS_TO_PREPARE ... default set to 4, note that the user gets a DAYS_TO_PREPARE + 1 message, i.e. if set to 4, the message will read 'losgehen kann es frühestens in 5 Tagen'

export default config