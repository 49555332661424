/*
Emoji Source: https://www.google.com/get/noto/help/emoji/ (and the Github repo: https://github.com/googlei18n/noto-emoji/)
*/

import logo_white from './logo_appentura_white.svg'
import logo_black from './logo_appentura_black.svg'

import icon_event_activity from './icon_event_activity_white.svg'
import icon_event_finish from './icon_event_finish_white.svg'
import icon_event_instruction from './icon_event_activity_white.svg'
import icon_event_message from './icon_event_message_white.svg'
import icon_event_review from './icon_event_review_white.svg'
import icon_event_selfie from './icon_event_selfie_white.svg'
import icon_event_start from './icon_event_start_white.svg'
import icon_event_travel_bus from './icon_event_travel_bus_white.svg'
import icon_event_travel_car from './icon_event_travel_car_white.svg'
import icon_event_travel_train from './icon_event_travel_train_white.svg'
import icon_event_travel_tram from './icon_event_travel_tram_white.svg'
import icon_event_travel_walking from './icon_event_travel_walking_white.svg'
import icon_event_travel_bike from './icon_event_travel_bike_white.svg'

import icon_header_help from './icon_header_help_green.svg'
import icon_header_phone from './icon_header_phone_green_filled_white.svg'
import icon_header_chat from './icon_header_chat_green_filled_white.svg'

import icon_misc_route from './icon_misc_route_white.svg'
import icon_misc_gift from './icon_misc_gift_white.svg'
import icon_misc_chevron_down from './icon_misc_chevron_down_white.svg'
import icon_misc_chevron_circle from './icon_misc_chevron_circle_green.svg'
import icon_misc_bell from './icon_misc_bell_white.svg'

import icon_social_facebook from './icon_social_facebook_white.svg'
import icon_social_twitter from './icon_social_twitter_white.svg'
import icon_social_instagram from './icon_social_instagram_white.svg'
import icon_social_email from './icon_social_email_white.svg'
import icon_social_whatsapp from './icon_social_whatsapp_white.svg'

import icon_action_selfie from './icon_action_selfie_white.svg'
import icon_action_send from './icon_action_send_green.svg'
import icon_action_send_disabled from './icon_action_send_disabled.svg'
import icon_action_trash from './icon_action_trash_green.svg'
import icon_action_write from './icon_action_write_green.svg'
import icon_action_share from './icon_action_share_green.svg'

import emoji_astonished_face from './emoji/astonished_face.svg'
import emoji_face_savouring_delicious_food from './emoji/face_savouring_delicious_food.svg'
import emoji_grinning_face_with_smiling_eyes from './emoji/grinning_face_with_smiling_eyes.svg'
import emoji_smiling_face_with_heart_shaped_eyes from './emoji/smiling_face_with_heart_shaped_eyes.svg'
import emoji_smiling_face_with_sunglasses from './emoji/smiling_face_with_sunglasses.svg'

import logo_google_review from "./logo_google_review.png";

import pato_duck from './pato-duck.gif';


const images = {
  logo: {
    white:logo_white,
    black:logo_black,
  },
  events : {
    activity:icon_event_activity,
    finish:icon_event_finish,
    instruction:icon_event_instruction,
    message:icon_event_message,
    review:icon_event_review,
    selfie:icon_event_selfie,
    start:icon_event_start,
    travel: {
      bus:icon_event_travel_bus,
      car:icon_event_travel_car,
      train:icon_event_travel_train,
      tram:icon_event_travel_tram,
      walking:icon_event_travel_walking,
      bike:icon_event_travel_bike
    }
  },
  header: {
    help:icon_header_help,
    phone:icon_header_phone,
    chat:icon_header_chat
  },
  misc: {
    route:icon_misc_route,
    gift:icon_misc_gift,
    chevron:icon_misc_chevron_down,
    chevron_circle:icon_misc_chevron_circle,
    bell:icon_misc_bell,
    googleReview: logo_google_review
  },
  social: {
    facebook:icon_social_facebook,
    twitter:icon_social_twitter,
    instagram:icon_social_instagram,
    email:icon_social_email,
    whatsapp:icon_social_whatsapp
  },
  emoji: {
    astonished_face:emoji_astonished_face,
    face_savouring_delicious_food:emoji_face_savouring_delicious_food,
    grinning_face_with_smiling_eyes:emoji_grinning_face_with_smiling_eyes,
    smiling_face_with_heart_shaped_eyes:emoji_smiling_face_with_heart_shaped_eyes,
    smiling_face_with_sunglasses:emoji_smiling_face_with_sunglasses
  },
  actions: {
    selfie:icon_action_selfie,
    send:icon_action_send,
    send_disabled:icon_action_send_disabled,
    delete:icon_action_trash,
    write:icon_action_write,
    share:icon_action_share
  },
  gifs: {
    patoDuck: pato_duck
  }
}

export default images


