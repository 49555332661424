import React from "react"
import PropTypes from "prop-types"

import "./Modal.scss"

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.onClickClose = this.onClickClose.bind(this)
  }

  render() {
    if(this.props.visible) {
      return (
        <div className="c-modal">
          <div className="c-modal-close" onClick={this.onClickClose}>
            &times;
          </div>
          <div className="c-modal-title">
            {this.props.title}
          </div>
          <div className="c-modal-content">
            {this.props.children}
          </div>
        </div>
      )
    }
    else {
      return null
    }

  }

  onClickClose(e) {
    this.props.onClose(e)
  }
}

Modal.propTypes = {
  visible:PropTypes.bool.isRequired,
  title:PropTypes.string.isRequired,
  onClose:PropTypes.func.isRequired
}

Modal.defaultProps = {
  visible:false
}

export default Modal