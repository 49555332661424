import React from "react"
import PropTypes from "prop-types"

// components
import Block from "./Block"
import ModalHelp from './ModalHelp'

// styling and images
import images from './../images'
import "./Header.scss"

// font awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faPhone } from '@fortawesome/free-solid-svg-icons'

class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      modal_action:null
    }

    this.onClickChat = this.onClickChat.bind(this)
    this.onClickHelp = this.onClickHelp.bind(this)
    this.onModalHelpClose = this.onModalHelpClose.bind(this)

  }

  render() {
    let button_help = null
    let button_chat = null
    let button_phone = null

    if(this.props.audience === 'adventurer') {
      button_phone = (
        <div className="button phone" key="button-phone">
          <a href="tel:+41315112132">
            <FontAwesomeIcon icon={faPhone} />
          </a>
        </div>
      )
      button_chat = (
        <div className="button chat" key="button-chat" onClick={this.onClickChat}>
          <FontAwesomeIcon icon={faComment} />
        </div>
      )
      /* we do not use this atm
      button_help = (
        <div className="button help" key="button-help" onClick={this.onClickHelp}>
          <img src={images.header.help} alt="info" />
        </div>
      )
      */
    }

    return (
      [
        <Block id="header" className={this.props.fixed ? "fixed" : ''} key="header">
          {button_help}
          <div className="logo">
            <img src={images.logo.white} alt="Appentura" />
          </div>
          {button_phone}
          {button_chat}
        </Block>,
        this.render_modal_help()
      ]

    )
  }

  render_modal_help() {
    if(this.state.modal_action === 'help') {
      return <ModalHelp onClose={this.onModalHelpClose} key="modal_help"/>
    }
    else {
      return null
    }
  }

  onClickHelp() {
    this.setState({...this.state, modal_action:'help'})
  }

  onModalHelpClose() {
    this.setState({...this.state, modal_action:null})
  }

  onClickChat() {
    if(window.HappyFoxChat) {
      window.HappyFoxChat.expandChatbox();
    }
  }
}

Header.propTypes = {
  audience:PropTypes.oneOf(["adventurer", "watcher"]).isRequired,
  fixed:PropTypes.bool.isRequired
}

Header.defaultProps = {
  audience:"adventurer",
  fixed:true
}

export default Header

/*
Note: HappyfoxChat API documentation: https://developer.happyfoxchat.com/
 */