// Each block (header, instructions, footer, ticker) uses this as its container

import React from "react"
import PropTypes from "prop-types"

import "./Block.scss"

class Block extends React.Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  render() {
    return (
      <div id={this.props.id || ''}
           className={`block ${this.props.className || ''}`} onClick={this.onClick}>
        <div className={`block-inner${this.props.isMain? ' main':''}`}>
          {this.props.children}
        </div>
      </div>
    )
  }

  onClick(e) {
    if(this.props.onClick) {
      this.props.onClick(e)
    }
  }
}

Block.propTypes = {
  id:PropTypes.string,
  className:PropTypes.string,
  onClick:PropTypes.func,
  isMain:PropTypes.bool.isRequired
}

Block.defaultProps = {
  isMain:false
}

export default Block