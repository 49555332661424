import analytics from './analytics'
import api from './api'
import site from './site'
import firebase from './firebase'
import constants from './constants'

const environment = process.env.REACT_APP_ENVIRONMENT || 'development';

console.log('REACT_APP_ENVIRONMENT:', environment);

const config = {
  environment,
  analytics:analytics[environment],
  api:api[environment],
  site:site[environment],
  firebase:firebase[environment],
  constants:constants[environment]
};

export default config