import React, {useState} from "react"

import {Grid, Input, Checkbox} from 'semantic-ui-react'

function Companion({isHomeDelivery, isOnlineEvent, validate, persons, onChangeHasAdditional, onChangeAdditionalReceiverEmail}) {
  // state
  const [hasAdditional, setHasAdditional] = useState(false)
  const [additionalReceiverEmail, setAdditionalReceiverEmail] = useState(null)

  // this is only available if there is only one person by default
  if(persons !== 1) {
    return null
  }
  // home delivery or online event? we don't support this
  if(isHomeDelivery || isOnlineEvent) {
    return null
  }

  // changes in controls
  const onChange = (e, { name, value, checked }) => {
    if(name === "hasAdditional") {
      setHasAdditional(checked)
      onChangeHasAdditional(checked)
    }
    else if(name === "additionalReceiverEmail") {
      setAdditionalReceiverEmail(value)
      onChangeAdditionalReceiverEmail(value)
    }
  }

  

  // render
  return <>
    <Grid.Row><Grid.Column width={16}><h2>Begleitperson</h2></Grid.Column></Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <Checkbox label='Ich möchte jemanden zusätzlich mitnehmen'
                  checked={hasAdditional}
                  name="hasAdditional"
                  onChange={onChange}
        />
      </Grid.Column>
      {hasAdditional &&
        <Grid.Column>
          <p>Gib die Mail-Adresse der Begleitperson an, wir werden sie kontaktieren und alles Übrige organisieren.</p>
          <Input 
            type='email'
            name='additionalReceiverEmail'
            placeholder='Mail-Adresse der Begleitperson'
            fluid={true}
            value={additionalReceiverEmail || ''}
            onChange={onChange}
          />
          {validate && !additionalReceiverEmail && <p className="error">Bitte gib die Mail-Adresse der Begleitperson an.</p>}
        </Grid.Column>
      }
    </Grid.Row>
  </>

}

export default Companion