import React, {useState, useEffect} from 'react';
import _ from "lodash"
import superagent from 'superagent'
import PropTypes from 'prop-types'
import config from './../../config'

import {
  Grid,
  Form,
  Input,
  Checkbox,
  TextArea,
  Button
} from 'semantic-ui-react'


import Block from './../../components/Block'
import Header from './../../components/Header'
import Footer from './../../components/Footer'
import Loader from "./../../components/Loader"

import OrderedForm from "./OrderedForm"
import RedeemMessage from "./RedeemMessage"

import "./Ordered.scss"

const DEFAULT_TIMEFRAMES = {
  standard:5,
  homedelivery:2,
  onlineevent:5
}

function Ordered({onSchedule, adventure}) {
  const [redeemTimeframes, setRedeemTimeframes] = useState({standard:DEFAULT_TIMEFRAMES.standard, homedelivery:DEFAULT_TIMEFRAMES.homedelivery, onlineevent:DEFAULT_TIMEFRAMES.onlineevent})
  const [isBusy, setIsBusy] = useState(false)
  const [state, setState] = useState({
    isSubmitted: false,
    isSubmitting: false,
    /* form values */
    name: adventure.RecieverRealFirstName || adventure.RecieverName || null,
    date: null,
    time: null,
    location: null,
    transportOption: 'oeffentlicher Verkehr',
    gaCount: 0,
    htCount: 0,
    comments: adventure.ScheduleComments || null,
    email: adventure.RecieverEmail || null,
    phone: adventure.RecieverPhone || null,
    newsletter: true,
    hasAdditional: false,
    additionalReceiverEmail: null
  })

  //console.log(state);
  //console.log(adventure)
  // mount
  useEffect(() => {
    loadRedeemTimeframe()
  }, [])
  
  const loadRedeemTimeframe = async() => {
    setIsBusy(true)
    try {
      let result = await superagent.get(`${config.api.root}/surpriseproperties/REDEEM_TIMEFRAME`)
      let resultValue = result.body.value || {standard:4, homedelivery:1}
      setRedeemTimeframes({
        standard:isNaN(resultValue.standard) ? DEFAULT_TIMEFRAMES.standard : Number(resultValue.standard),
        homedelivery:isNaN(resultValue.homedelivery) ? DEFAULT_TIMEFRAMES.homedelivery : Number(resultValue.homedelivery),
        onlineevent:isNaN(resultValue.onlineevent) ? DEFAULT_TIMEFRAMES.onlineevent : Number(resultValue.onlineevent),
      })
    }
    catch(err) {
      console.error(err)
    }
    setIsBusy(false)
  }

  // changes in textinputs and checkboxes
  const onChange = (e, { name, value, checked }) => {
    let change = {}
    if (value !== undefined) {
      change[name] = value
    } else {
      change[name] = checked
    }
    setState(_.merge({...state}, change))
  }

  // validates the state
  const validate = (s) => {
    return s.name && s.date && s.time && s.location && s.email && s.phone && (!s.hasAdditional || s.additionalReceiverEmail);
  }

  // submit data
  const submit = () => {
    // TODO this is an ugly hack because we get the date from the controls in near useless format
    let start = null
    try {
      let date_parts = state.date.split('.')

      let day = Number(date_parts[0])
      let month = Number(date_parts[1])
      let year = Number(date_parts[2])

      start = new Date(year, month - 1, day)
      start.setHours(Number(state.time) / 60)
      start.setMinutes(0)
      start.setSeconds(0)
      start.setMilliseconds(0)
    }
    catch(err) {
      start = null //console.log(err)
    }
    
    setState({...state, isSubmitted: true })

    const scheduleData = {
      name: state.name,
      location : state.location,
      email : state.email,
      phone: state.phone,
      start:start ? start.toISOString() : null,
      comments: state.comments,
      transportOption: state.transportOption,
      gaCount: state.gaCount,
      htCount: state.htCount,
      newsletter: state.newsletter,
      hasAdditional: state.hasAdditional,
      additionalReceiverEmail: state.additionalReceiverEmail
    }
    if (validate(state)) {
      onSchedule(scheduleData);
    }
  }

  // values needed to render
  let redeemOffsetDays = redeemTimeframes.standard;
  if(adventure.IsHomeDelivery) {
    redeemOffsetDays = redeemTimeframes.homedelivery;
  } 
  else if(adventure.IsOnlineEvent) {
    redeemOffsetDays = redeemTimeframes.onlineevent;
  }
  const isValid = validate(state)
  const country = (adventure.Country || "ch").toLowerCase();

  // content we will render
  let content = (
    <>
      <OrderedForm.Intro adventure={adventure} />

        <OrderedForm.Name validate={state.isSubmitted} initialValue={state.name} onChange={name => setState({...state, name})} />

        <div className='title'><h2>Datum & Zeit</h2></div>
        <OrderedForm.AvailableTimes adventure={adventure} />
        <OrderedForm.StartDate validate={state.isSubmitted} onChange={date => setState({...state, date})} redeemOffsetDays={redeemOffsetDays} />
        <OrderedForm.StartTime validate={state.isSubmitted} onChange={time => setState({...state, time})} isHomeDelivery={adventure.IsHomeDelivery} isOnlineEvent={adventure.IsOnlineEvent} />
        
        <form autoComplete="off">
          <input autoComplete='off' name="hidden" type="text" style={{display:"none"}} />
          <OrderedForm.Location adventure={adventure} validate={state.isSubmitted} onChange={location => setState({...state, location})} />
        </form>
        
        <OrderedForm.Transport 
          country={country}
          isHomeDelivery={adventure.IsHomeDelivery} 
          isOnlineEvent={adventure.IsOnlineEvent}
          onChangeTransportOption={transportOption => setState({...state,transportOption})} 
          onChangeGaCount={gaCount => setState({...state, gaCount})}
          onChangeHtCount={htCount => setState({...state, htCount})}
        />

      <div className='title'><h2>Wie können wir mit dir Kontakt aufnehmen?</h2></div>
        <Grid.Row>
          <Grid.Column>
            <p>Damit wir dich benachrichtigen können, sobald deine Überraschung organisiert ist, brauchen wir nun noch deine Mail-Adresse.</p>
          </Grid.Column>
          <Grid.Column>
            <Input 
              type='email'
              name='email'
              placeholder= {`deine@e-mail.${country}`}
              fluid={true}
              value={state.email || ''}
              onChange={onChange}
            />
            {state.isSubmitted && !state.email && <p className="error">Wir brauchen deine E-Mail-Adresse um dich zu informieren, sobald deine Überraschung bereit ist.</p>}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <p>Allenfalls müssen wir dich kurzfristig erreichen, dazu brauchen wir deine Mobilnummer. Wir treiben mit deiner Nummer wie mit allen anderen Informationen keinen Schabernack, versprochen!</p>
          </Grid.Column>
          <Grid.Column>
            <Input 
            type='tel'
              name='phone'
              placeholder={adventure.Country.toLowerCase() === "ch" ? "+41 12 3456789" : "+49 12 3456789"}
              fluid={true}
              value={state.phone || ''}
              onChange={onChange}
            />
            {state.isSubmitted && !state.phone && <p className="error">Wir brauchen deine Mobilnummer um dich allenfalls kurzfristig zu erreichen.</p>}
          </Grid.Column>
        </Grid.Row>

        <div className='title'><h2>Fehlt noch etwas?</h2></div>
        <Grid.Row>
          <Grid.Column>
            <p>Gibt es noch etwas, was wir bei der Planung beachten müssen?</p>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <TextArea 
                fluid="true"
                autoHeight
                placeholder=''
                name='comments'
                value={state.comments || ''}
                onChange={onChange}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>

        <OrderedForm.Companion 
          isHomeDelivery={adventure.IsHomeDelivery} 
          isOnlineEvent={adventure.IsOnlineEvent}
          persons={adventure.Persons} 
          onChangeHasAdditional={hasAdditional => setState({...state, hasAdditional})} 
          onChangeAdditionalReceiverEmail={additionalReceiverEmail => setState({...state, additionalReceiverEmail})}
        />

        <div className='title'><h2>Newsletter</h2></div>
        <Grid.Row>
          <Grid.Column width={16}>
            <Checkbox label='Ich möchte den Appentura-Newsletter abonnieren, um Neuigkeiten zu erfahren und von speziellen Angeboten zu profitieren.'
                      checked={state.newsletter}
                      name="newsletter"
                      onChange={onChange}
                      className='newsletter-checkbox'
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <p>Das wärs, jetzt kannst du uns arbeiten lassen.</p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16} textAlign='center' className='button'>
            {state.isSubmitted && !isValid &&
              <p className="error">Es sind noch nicht alle Angaben vollständig.</p>
            }
            <Button onClick={submit}>
              {adventure.IsHomeDelivery || adventure.IsOnlineEvent ? "abschicken!" : "Erstellt meine Überraschung!"}
            </Button>
          </Grid.Column>
        </Grid.Row>


    </>
  )

  // render
  return (
    <>
      <Header audience="adventurer" fixed={false} key="header"/>
        <Block id="surprise-ordered" key="content" isMain={true}>
          <RedeemMessage isHomeDelivery={adventure.IsHomeDelivery} isOnlineEvent={adventure.IsOnlineEvent} />
          {isBusy ? <Loader /> : content}
        </Block>
      <Footer key="footer" />
    </>
  ) 
}


Ordered.propTypes = {
  adventure:PropTypes.object.isRequired,
  onSchedule:PropTypes.func.isRequired
}

export default Ordered;