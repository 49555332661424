const REACTIONS_KEY = 'appentura_live_reactions'

/**
 * Keeps track of reactions visitors have posted to events. To do so, it stores flags inside the localStorage.
 * @param eventId
 * @param name
 * @returns {boolean} Returns true if the reaction could be added (i.e. if it was no previously submitted)
 */
function addReaction(eventId, name) {
  name = name.toLowerCase()
  let added = false
  let reactions = JSON.parse(window.localStorage.getItem(REACTIONS_KEY) || '{}')
  if(!reactions[eventId]) {
    reactions[eventId] = []
  }
  if(!reactions[eventId].includes(name)) {
    added = true
    reactions[eventId].push(name)
  }
  window.localStorage.setItem(REACTIONS_KEY, JSON.stringify(reactions))
  return added
}


export default {addReaction}