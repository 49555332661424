

const config = {
  development: {
    surprise:`http://${window.location.hostname}:3002/s`,
    ticker:`http://${window.location.hostname}:3002/v`
  },
  staging: {
    surprise:`https://surprise-staging.appentura.ch/s`,
    ticker:`https://live-staging.appentura.ch/v`
  },
  production: {
    surprise:`https://surprise.appentura.ch/s`,
    ticker:`https://live.appentura.ch/v`
  }
};

export default config