import React from "react"
import PropTypes from "prop-types"
import * as DateTime from './../../../utils/DateTime'

import Countdown from "../../../components/Countdown"
import Block from './../../../components/Block'
import PreparingMessage from "../PreparingMessage"

/**
 * This component display a countdown of the time left until the adventure starts and all important information.
 * It is used when the Surprise is in the Accepted state (see Accepted.js) and when it is in the Ready state and the visitor is too early (see Ready.js)
 * Once the countdown is over, the onCountdownOver property function is fired - allowing the Ready state view to switch its display mode.
 */
class TooEarly extends React.PureComponent {
  constructor(props) {
    super(props)

    this.onCountdownOver = this.onCountdownOver.bind(this)
  }

  render() {
    const adventure = this.props.adventure;

    const date = DateTime.ToShortDate(adventure.StartTime)
    const time = DateTime.ToShortTime(adventure.StartTime)
    const enddate = DateTime.ToShortDate(adventure.EndTime)
    const endtime = DateTime.ToShortTime(adventure.EndTime)

    let dateAndTime 
    if(adventure.IsHomeDelivery) {
      dateAndTime = (
        <p>
          Am <b>{date}</b> um <b>{time}</b> Uhr kommt deine Überraschung zu dir. Alles was du tun musst, ist dann an folgender Adresse zu sein: <b>{adventure.UserStartLocationText}</b>.
        </p>
      )
    } 
    else if(adventure.IsOnlineEvent) {
      dateAndTime = (
        <p>
          Am <b>{date}</b> um <b>{time}</b> Uhr findet deine Überraschung statt.
        </p>
      )
    }
    else {
      dateAndTime = (
        <p>
          Am <b>{date}</b> um <b>{time} Uhr</b> geht’s los an folgender Adresse: <b>{adventure.UserStartLocationText}</b>.
        </p>
      )
    }

    let conditionsInfo = null
    let conditionsUl = null
    let conditionsLi = adventure.Conditions.map((c, index) => {
      return <li key={index+1}>{ c.Text }</li>
    })
    if(!adventure.IsHomeDelivery && !adventure.IsOnlineEvent) {
      conditionsLi.unshift(<li key={0}>Smartphone oder ein anderes Gerät, um unterwegs meine Überraschungs-Seite aufzurufen</li>)
    }
    if(conditionsLi.length > 0) {
      conditionsInfo = <p>Hier zur Erinnerung nochmals {adventure.IsHomeDelivery || adventure.IsOnlineEvent ? "worauf du achten solltest" : "alle Bedingungen:"}</p>
      conditionsUl = <ul>{conditionsLi}</ul>
    }

    let endsAt = null
    if(!adventure.IsHomeDelivery && !adventure.IsOnlineEvent) {
      endsAt = <p>Die Überraschung endet {(date !== enddate ? "am " + enddate : "")} ungefähr um {endtime} Uhr.</p>
    }

    let participants = <p>{adventure.IsHomeDelivery ? "Die Überraschungs ist gedacht für" : "Die Überraschung ist gültig für"} {adventure.Persons} {adventure.Persons > 1 ? "Personen" : "Person"}.</p>
    if(adventure.IsOnlineEvent) {
      participants = null;
    }

    let meansOfTransport = null
    if(!adventure.IsHomeDelivery && !adventure.IsOnlineEvent && adventure.TransportOptions && adventure.TransportOptions.length > 0) {
      meansOfTransport = <p>Als Transportmittel wurde <b>{adventure.TransportOptions[0]}</b> gewählt.</p>
    }

    return (

      <Block id="surprise-accepted" key="content" isMain={true}>
        <PreparingMessage isHomeDelivery={adventure.IsHomeDelivery} isOnlineEvent={adventure.IsOnlineEvent} />
        
        <h1>{ this.props.title || "Deine Überraschung ist bereit!"}</h1>

        <Countdown time={adventure.StartTime} onCountdownOver={this.onCountdownOver} />

        {dateAndTime}
        <p>Du kannst dann einfach wieder diese Seite aufrufen, dann siehst Du, wie es weiter geht.</p>
        {endsAt}
        {participants}
        {meansOfTransport}
        {conditionsInfo}
        {conditionsUl}
      </Block>
    )
  }

  onCountdownOver() {
    this.props.onCountdownOver()
  }
}

TooEarly.propTypes = {
  adventure:PropTypes.object.isRequired,
  title:PropTypes.string.isRequired,
  onCountdownOver:PropTypes.func.isRequired
}

TooEarly.defaultProps = {
  title:"Du bist zu früh dran"
}

export default TooEarly