import React from 'react';
import superagent from 'superagent';
import config from '../config/index'
import zenscroll from 'zenscroll'

import LoaderOverlay from '../components/LoaderOverlay'

import NotFinished from './surpriseviews/NotFinished'
import Ordered from './surpriseviews/Ordered'
import Scheduled from './surpriseviews/Scheduled'
import Organized from './surpriseviews/Organized'
import Accepted from './surpriseviews/Accepted'
import Ready from './surpriseviews/Ready'
import Finished from './surpriseviews/Finished'
import Reviewed from './surpriseviews/Reviewed'
import Archived from './surpriseviews/Archived'
import Blocked from './surpriseviews/Blocked'
import Unknown from './surpriseviews/Unknown'
import Prepaid from './surpriseviews/Prepaid'
import BuddySelected from './surpriseviews/BuddySelected'

class Surprise extends React.Component {

  steps = [NotFinished, Ordered, Scheduled, Organized, Accepted, Ready, Finished, Reviewed, Archived, Blocked]
  state = { isLoading: true }

  componentDidMount() {

    // get params from query string
    const params = new URLSearchParams(this.props.location.search)

    // there are different possible modes for surprise view - live (default), test, admin. Append the corresponding value as a query parameter, i.e.: ?mode=test or ?mode=admin ... if no mode is set, 'live9 is used
    // - live : regular mode the end-user gets
    // - test: try the adventure without writing anything to the database ... add &step=<step> to display a particular step
    // - admin: view what the end-user is seeing without affecting the database (e.g. no logs are written)
    this.setState({ mode: params.get('mode') || 'live', step: params.get('step') || 0 }, () => {
      this.loadAdventure()
    })
  }

  // actions which are executed for surprise
  loadAdventure = () => { this.executeSurpriseAction("load", {}) }
  onSchedule = (data) => { this.executeSurpriseAction("schedule", data) }
  onReject = (data) => { this.executeSurpriseAction("reject", data) }
  onReschedule = (data) => { this.executeSurpriseAction("reschedule", data) }
  onAccept = (data) => { this.executeSurpriseAction("accept", data) }
  onRate = (data) => { this.executeSurpriseAction("rate", data) }

  /**
   * Executes POST request for surprise and updates state with newly loaded surprise object from server 
   * It is possible to ignoreResult, so in a offline mode scenario it still works
   */
  executeSurpriseAction(action, data, ignoreResult, ignoreError) {
    // only execute actions when load action, schedule action, or live mode
    if (action === "load" || action === "schedule" || this.state.mode === "live") {
      if (this.props.match.params.id) {
        if (!ignoreResult) {
          this.setState({ isLoading: true });
        }
        superagent.post(`${config.api.root}/surprise/${action}/${this.props.match.params.id}`)
          .query({ mode: this.state.mode })
          .send(data)
          .end((err, res) => {

            if (err && err.status === 404) {
              // if not found - redirect to 404 page
              this.props.history.push("/404");
            } else if (err) {
              // if any other error - and not ignoring result
              if (!ignoreResult && !ignoreError) {
                this.props.history.push("/error/" + this.props.match.params.id)
              }
            } else {
              if (!ignoreResult) {
                // surprise actions ALWAYS must return adventure in body
                if (res.body) {
                  const adventure = res.body

                  // output link to ticker (if applicable)
                  if(adventure.LiveId && action === "load") {
                    console.log(`Live-View:${config.site.ticker}/${adventure.LiveId}`)
                  }

                  // if test mode - fake status, step and starttime
                  if (this.state.mode === "test") {
                    adventure.Status = 6 // READY
                    adventure.CurrentStep = this.state.step
                    adventure.StartTime = new Date()
                  }

                  this.setState({ adventure })
                  window.scrollTo(0, 0)
                } else {
                  if (!ignoreError) {
                    this.props.history.push("/error/" + this.props.match.params.id)
                  }
                }
              }
            }
            this.setState({ isLoading: false });
          });
      } else {
        this.props.history.push("/404");
      }
    }
  }


  onPrevious = (e) => {
    let adventure = { ...this.state.adventure };
    if (adventure.CurrentStep > 0) {
      if (this.state.mode === "live") {
        this.executeSurpriseAction("gotostep", { step: adventure.CurrentStep - 1 })
      }
      else {
        adventure.CurrentStep--
        this.setState({ adventure })

        zenscroll.toY(0, 500) // second parameter is time in miliseconds
      }
    }    
  }

  onNext = (e) => {
    let adventure = { ...this.state.adventure };
    if (adventure.Steps.length > adventure.CurrentStep) {
      if (this.state.mode === "live") {
        this.executeSurpriseAction("gotostep", { step: adventure.CurrentStep + 1 })
      }
      else {
        adventure.CurrentStep++
        this.setState({ adventure })

        zenscroll.toY(0, 500) // second parameter is time in miliseconds
      }
    }
  }

  render() {
    

    const adventure = this.state.adventure;

    // default content is loading screen
    let content = <LoaderOverlay />;

    // if there is a adventure
    if (adventure) {
      switch(adventure.Status) {
        case 1:
          content = <NotFinished adventure={adventure}/>
          break
        case 2:
          content = <Ordered adventure={adventure} onSchedule={this.onSchedule} />
          break
        case 3:
          content = <Scheduled adventure={adventure}/>
          break
        case 4:
          content = <Organized adventure={adventure} onAccept={this.onAccept} onReject={this.onReject} onReschedule={this.onReschedule}/>
          break
        case 5:
          content = <Accepted adventure={adventure}/>
          break
        case 6:
          content = <Ready adventure={adventure} onNext={this.onNext} onPrevious={this.onPrevious} isLoading={this.state.isLoading} />
          break
        case 7:
          content = <Finished adventure={adventure} onRate={this.onRate}/>
          break
        case 8:
          content = <Reviewed adventure={adventure}/>
          break
        case 9:
          content = <Archived adventure={adventure}/>
          break
        case 10:
          content = <Blocked adventure={adventure}/>
          break
        case 11:
          content = <Prepaid adventureId={adventure._id} />
          break
        case 12:
          content = <BuddySelected adventureId={adventure._id} />
          break
        default:
          content = <Unknown adventure={adventure} />
          break
      }

    }

    return (content)
  }

}

export default Surprise