import React from "react"
import PropTypes from "prop-types"
import config from "./../config"

import Modal from "./Modal"

import images from "./../images"
import "./ModalShare.scss"

class ModalShare extends React.Component {
  constructor(props) {
    super(props)
    this.onClickClose = this.onClickClose.bind(this)
  }

  render() {
    let url = `${config.site.ticker}/${this.props.liveId}`

    let email_subject = "Meine Appentura Überraschung hat angefangen!"
    let email_body = `Du kannst mit mir miterleben: ${url}`
    let email_to = this.props.reserverEmail || ''
    let email_href = encodeURI(`mailto:${email_to}?Subject=${email_subject}&Body=${email_body}&`)

    let twitter_text = "Meine Appentura Überraschung hat angefangen!"
    let twitter_hashtags = "Appentura"

    let whatsapp_text = encodeURIComponent(`Verfolge jetzt live mein Appentura Überraschungs-Erlebnis: ${url}`)

    return (
      <Modal visible={true} title="Teilen" onClose={this.onClickClose}>
        <div id="modal-share" className="block">
          <div className="block-inner">
            <div className="explanation">
              Teile die Timeline mit deinen Freunden, damit sie dein Überraschungs-Erlebnis mitverfolgen können
            </div>

            <div className="items">
              <div className="item">
                <a href={`http://www.facebook.com/sharer.php?u=${url}`} target="_blank" rel="noopener noreferrer">
                  <img src={images.social.facebook} alt="Facebook" />
                </a>
              </div>
              <div className="item">
                <a href={`https://twitter.com/share?url=${url}&amp;text=${twitter_text}&amp;hashtags=${twitter_hashtags}`} target="_blank" rel="noopener noreferrer">
                  <img src={images.social.twitter} alt="Twitter" />
                </a>
              </div>
              <div className="item">
                <a href={`whatsapp://send?text=${whatsapp_text}`}>
                  <img src={images.social.whatsapp} alt="Whatsapp" />
                </a>
              </div>
            </div>

            <div className="explanation">
              {`Lass ${this.props.reserverName} via-Email wissen, dass deine Appentura Überraschung angefangen hat.`}
            </div>
            <div className="items">
              <div></div>
              <div className="item">
                <a href={email_href}>
                  <img src={images.social.email} alt="Email" />
                </a>
              </div>
              <div></div>
            </div>

            <div className="console">
              <button className="green" onClick={this.onClickClose}>schliessen</button>
            </div>
          </div>
        </div>

      </Modal>
    )

  }

  onClickClose(e) {
    if(e.preventDefault) {
      e.preventDefault()
    }
    this.props.onClose()
  }
}

ModalShare.propTypes = {
  liveId:PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  reserverEmail:PropTypes.string.isRequired,
  reserverName:PropTypes.string.isRequired
}

ModalShare.defaultProps = {
  reserverEmail:"",
  reserverName:""
}
export default ModalShare

/*
Resources: https://simplesharebuttons.com/html-share-buttons
 */