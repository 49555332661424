import React from "react"
import PropTypes from "prop-types"

import Event from "./Event"

import "./Message.scss"

class Message extends React.Component {
  render() {
    let text = this.props.event.text
    let title = `${this.props.about.for}`

    return (
      <Event event={this.props.event} className="event-message" title={title} deletable={true} onDelete={this.props.onDelete}  onReaction={this.props.onReaction} audience={this.props.audience}>
        <div className="message-text">
          "{text}"
        </div>
      </Event>
    )
  }
}

Message.propTypes = {
  about: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReaction: PropTypes.func.isRequired,
  audience:PropTypes.oneOf(["adventurer", "watcher"]).isRequired
}

Message.defaultProps = {
  audience:"adventurer"
}

export default Message