import React, { useState } from "react";
import Block from "./Block"

type TimelineHeaderProps = {
  adventurer: string,
  audience:"adventurer"|"watcher",
  onClickShare: () => void,
  onSubmitMessage: (message:string) => void,
}
export default function TimelineHeader({audience, adventurer, onClickShare, onSubmitMessage} : TimelineHeaderProps) {
  // state
  const [message, setMessage] = useState<string>("");
  const [messageSendEnabled, setMessageSendEnabled] = useState<boolean>(false);

  // user inputs message to send
  const onChangeMessageText = (e:any) => {
    const message = (e.target.value || '');
    setMessage(message);
    setMessageSendEnabled(message.trim().length > 0);
  }

  // user wants to send message
  const onClickSendMessage = () => {
    onSubmitMessage(message)
    setMessage("");
  }


  // render
  let header;
  switch(audience) {
    case "adventurer":
      header = (
        <Block>
          <div className="header-adventurer">
            <div className="header-adventurer-first">
              <span>Teile diese Seite mit deinen Freunden</span>
              <button onClick={onClickShare} className="share">Teilen</button>
            </div>
          </div>
          <div className="header-adventurer">
            <div className="header-adventurer-second">
              <textarea placeholder="schreibe einen Eintrag" rows={1} value={message} onChange={onChangeMessageText}></textarea>
              <div className={`commentButton ${messageSendEnabled ? '' : 'disabled'}`} onClick={onClickSendMessage}>
                <button>Eintragen</button>
              </div>
            </div>
          </div>
        </Block>
      )
      break
    case "watcher":
      header = (
        <div className="header-watcher">
          <div className="header-watcher-title">
            Überraschungs-Erlebnis von {adventurer}
          </div>
          <div className="header-watcher-pulse"><div className="header-watcher-pulse-inner"></div></div>
          <div className="header-watcher-explanation">
            Beobachte was {adventurer} während der Überraschung erleben darf
          </div>

        </div>
      )
      break
    default:
      header = null
      break
  }

  return header;
}