import React from "react"
import PropTypes from "prop-types"

import Modal from "./Modal"

import "./ModalSelfie.scss"

class ModalSelfie extends React.Component {
  render() {
    let img = null
    if(this.props.base64ImageData) {
      img = <img src={this.props.base64ImageData} alt="selfie" />
    }
    return (
      <Modal visible={true} title="Foto" onClose={this.onCancel}>
        <div id="modal-selfie" className="block">
          <div className="block-inner">
            <div id="modal-selfie-image">
              {img}
            </div>
            <div id="modal-selfie-console">
              <div id="modal-selfie-marketing">
                <div className="text">
                  Appentura darf das Bild zu Marketingzwecken verwenden
                </div>
              </div>
              <div id="modal-selfie-buttons">
                <button className="red" data-marketing="no" onClick={this.onAccept}>lieber nicht</button>
                <button className="green" data-marketing="yes" onClick={this.onAccept}>ja</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  onCancel = (e) => {
    if(e.preventDefault) {
      e.preventDefault()
    }
    this.props.onCancel()
  }

  onAccept = (e) => {
    e.preventDefault()
    let marketingAllowed = e.target.dataset.marketing === "yes" ? true : false
    this.props.onAccept({
      marketingAllowed:marketingAllowed
    })
  }
}

ModalSelfie.propTypes = {
  base64ImageData:PropTypes.string,
  onCancel:PropTypes.func.isRequired,
  onAccept:PropTypes.func.isRequired
}

export default ModalSelfie