import React, {useState, useEffect} from "react"
import _ from "lodash"
import * as DateTime from '../../../utils/DateTime'

function Intro({adventure}) {
  // state
  const [listItems, setListItems] = useState([])

  // mount
  useEffect(() => {
    // gather items
    const lis = []
    lis.push(`Deine Überraschung ist gültig für ${adventure.Persons} ${adventure.Persons > 1 ? "Personen" : "Person"}.`)
    if(adventure.Season) {
      lis.push(`Deine Überraschung kann zwischen ${adventure.Season} stattfinden.`)
    }
    lis.push(`Rechne mit einer Dauer von ungefähr ${DateTime.DurationInHours(adventure.EstimatedDuration)} Stunden für deine Überraschung.`)
    if(!adventure.IsHomeDelivery && !adventure.IsHomeDelivery) {
      lis.push(`Der Transport zu deiner Überraschung ist nicht inbegriffen. Falls nötig wirst du mit deinem eigenen Fahrzeug oder dem öffentlichen Verkehr reisen.`)
    }
    if(adventure.IsHomeDelivery || adventure.IsOnlineEvent) {
      lis.push(`Deine Überraschung wird bei dir zu Hause stattfinden.`)
    }
    else {
      if(adventure.Region) {
        const region = (adventure.Region || "").split(" ").filter(part => part.toLowerCase() !== "region").join(" ");
        lis.push(`Deine Überraschung wird voraussichtlich in der Region ${region} stattfinden.`)
      }
    }
    // set items
    setListItems(lis)

  }, [])

  
  // the heading differs depending on whether the adventure was sold in a brick and mortar store or online
  let heading = null
  if(adventure.IsSoldOffline) {
    heading = (
      <div>
        <h1>Hallo :)</h1>
        <p>Willkommen bei deiner Appentura-Überraschung. Bevor es los gehen kann, brauchen wir zur Planung noch einige Informationen von Dir.</p>
      </div>
    )
  }
  else {
    let personalCommentText = _.toString(adventure.PersonalComment).trim()
    let personalComment = personalCommentText.length > 0 ? <p className="personal-comment">{`„${personalCommentText}“`}</p> : null // »«
    heading = (
      <div id="surprise-ordered-intro">
        <h1>Hallo {adventure.RecieverName}</h1>
        <p>Willkommen bei deiner persönlichen Überraschung, kreiert und geschenkt von <span className="reserver-name">{adventure.ReserverName}</span>.</p>
        {personalComment}
        <p>Bevor es los gehen kann, brauchen wir zur Planung noch einige Informationen von dir.</p>
      </div>
    )
  }

  // gather list items

  return (
    <>
      {heading}
      <ul>
        {
          listItems.map((item, index) => <li key={index}>{item}</li>)
        }
      </ul>
    </>

  )
}

export default Intro


