import 'babel-polyfill' // hopefully this makes the whole thing fly in IE11 (and maybe even earlier)
import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'

// views
import Surprise from './pages/Surprise'
import Ticker from './pages/Ticker'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Error from './pages/Error'

//css
import './App.scss'

// print version stamp
console.log("v1.3.1")

// the App
const App = () => (
  <Router>
    <div id="app">
      <Switch>
        {/* redirect old adventure urls */}
        <Route exact path="/Adventure/Index/:id" render={props => (<Redirect exact to={`/s/${props.match.params.id}`} />)} />

        <Route path="/s/:id" component={Surprise} />
        <Route path="/v/:id" component={Ticker} />

        <Route exact path="/" component={Home} />
        <Route exact path="/404" component={NotFound} />
        <Route exact path="/error" component={Error} />
        <Route exact path="/error/:id" component={Error} />

        <Redirect to="/404" />
      </Switch>
    </div>
  </Router>
)

export default App