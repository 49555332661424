import React, {useState, useEffect} from 'react';
import {ApiBuddy as Api} from "../../utils/api";

// components
import Block from './../../components/Block';
import Header from './../../components/Header';
import Footer from './../../components/Footer';
import Loading from './../../components/Loading';

// styling
import "./Prepaid.scss"

// the component
export default function Prepaid({adventureId}) {
  
  // state
  const [adventure, setAdventure] = useState(null);
  const [regions, setRegions] = useState(null);
  const [invalidFields, setInvalidFields] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    buddyName:"", buddyEmail:"", buddySelectionComment:"",
    participantCount:1, regionCode:"", regionName:"",
    adventurerName:"", adventurerEmail:"", adventurerPhone:"", adventurerComment:""
  });
  const [participantsOptionsLabels] = useState(["allein", "zu zweit", "zu dritt", "zu viert", "zu fünft", "zu sechst"]);
  // mount
  useEffect(() => {
    const load = async() => {
      const result = await Api("receiver", "load", {adventureId:adventureId});
      if(!result.success) {
        setError(result.error);
      }
      else {
        setAdventure(result.adventure);
        setRegions(result.regions);
      }
    }
    load();
  }, [adventureId])

  // submit
  const submit = async(e) => {
    setIsBusy(true);
    e.preventDefault();
    const payload = {...data, adventureId:adventure.id};
    const validation = validate(payload);
    
    if(validation.valid) {
      const result = await Api("receiver", "redeem", payload);
      if(result.success) {
        window.location.reload();
      }
      else {
        setError(result.error);
      }
    }
    else {
      setInvalidFields(validation.invalidFields);
      setIsBusy(false);
    }
  }
  
  const isInvalid = (fieldName) => {
    return invalidFields.includes(fieldName);
  }

  // make render content
  let content = null
  if(error) {
    console.error(error)
    content = <>
      <h1>{error.title || "Fehler"}</h1>
      <div className="prepaid-info">
        <p>Da ist etwas nicht richtig gelaufen:</p>
        <p>{error.message}</p>
      </div>
      <div className="prepaid-info">
        <p>Bitte nimm mit uns Kontakt auf</p>
      </div>
    </>
  }
  else if(!adventure || !regions) {
    content = <Loading />
  }
  else {
    // output a validation info?
    let validationError = null;
    if(invalidFields.length > 0) {
      validationError =  <div className="prepaid-form-error">Bitte fülle das Formular vollständing aus</div>
    }
    // region select options
    const regionOptions = regions.map(region => <option key={region.code} value={region.code}>{region.title}</option>);
    regionOptions.unshift(<option key="empty" value="">Region wählen</option>);
    
    // some content elements vary depending depending on whether the value is hidden or not
    const budgetInfo = adventure.hideValue ? null : `Beachte dabei, dass für deine Überraschung ein Budget von ${Number(adventure.value)} Franken zur Verfügung steht.`;
    const participantOptions = participantsOptionsLabels
      .filter((o, i) => {
        if(i > 1 && adventure.hideValue) {
          // if the value is hidden, only 1 and 2 participants is possible
          return false;
        }
        return true;
      })
      .map((o, i) => {
        let text = o;
        if(!adventure.hideValue && i > 0) {
          text += ` (CHF ${(Math.floor(Number(adventure.value * 10) / (i + 1)) / 10).toFixed(2)} pro Person)`;
        }
        return <option key={i} value={i + 1}>{text}</option>
      });

    // the content
    content = (
      <>
        <h1>Hallo</h1>

        <div className="prepaid-info">
          <p>Willkommen bei deiner persönlichen Überraschung!</p> 
          <p>Du darfst jetzt</p>
          <ul>
            <li>wählen, ob du die Überraschung lieber alleine oder zusammen mit Freunden erleben möchtest</li>
            <li>entscheiden, in welcher Region deine Überraschung stattfinden soll</li>
            <li>eine Person deines Vertrauens bestimmen, die den Inhalt deiner Überraschung wählen darf</li>
          </ul>
          <p>Fülle einfach dieses Formular aus, dann geht es weiter.</p>
        </div>

        <div className="prepaid-form">
          <h2>Deine Überraschung</h2>
          <p>
            <label htmlFor="region">Möchtest du die Überraschung alleine oder zusammen mit Freunden erleben? {budgetInfo}</label>
            <select value={data.participantCount} onChange={e => setData({...data, participantCount:Number(e.target.value)})} disabled={isBusy}>
              {participantOptions}
            </select>
          </p>
          <p className={isInvalid("regionCode") ? "invalid":""}  >
            <label htmlFor="regionCode">In welcher Region soll deine Überraschung stattfinden?</label>
            <select 
              name="regionCode" 
              value={data.region} 
              disabled={isBusy}
              onChange={e => {
                const region = regions.find(r => r.code === e.target.value);
                if(region) {
                  setData({...data, regionCode:region.code, regionName:region.title});
                }
                else {
                  setData({...data, regionCode:"", regionName:""});
                } 
              }}
            >
              {regionOptions}
            </select>
           </p>
          <br />

          <h2>Vertrauensperson, die den Inhalt deiner Überraschung bestimmen darf</h2>
          <p className={isInvalid("buddyName") ? "invalid":""}  >
            <label htmlFor="buddyName">Name</label>
            <input type="text" name="buddyName" value={data.buddyName} onChange={e => setData({...data, buddyName:e.target.value})} disabled={isBusy} />
          </p>
          <p className={isInvalid("buddyEmail") ? "invalid":""}  >
            <label htmlFor="buddyEmail">Mail-Adresse</label>
            <input type="email" name="buddyEmail" value={data.buddyEmail} onChange={e => setData({...data, buddyEmail:e.target.value})} disabled={isBusy}  />
          </p>
          <br />

          <h2>Deine Kontaktinformationen</h2>
          <p className={isInvalid("adventurerName") ? "invalid":""}  >
            <label htmlFor="adventurerName">Name</label>
            <input type="text" name="adventurerName" value={data.adventurerName} onChange={e => setData({...data, adventurerName:e.target.value})} disabled={isBusy}  />
          </p>
          <p className={isInvalid("adventurerEmail") ? "invalid":""}  >
            <label htmlFor="adventurerEmail">Mail-Adresse</label>
            <input type="email" name="adventurerEmail" value={data.adventurerEmail} onChange={e => setData({...data, adventurerEmail:e.target.value})} disabled={isBusy}  />
          </p>
          <p className={isInvalid("adventurerPhone") ? "invalid":""}  >
            <label htmlFor="adventurerPhone">Telefonnummer (brauchen wir nur im Notfall während der Überraschung)</label>
            <input type="phone" name="adventurerPhone" value={data.adventurerPhone} onChange={e => setData({...data, adventurerPhone:e.target.value})} disabled={isBusy}   />
          </p>
          <br />
          <p>
            <label htmlFor="adventurerComment">Willst du der Vertrauensperson noch etwas mitteilten?</label>
            <textarea name="adventurerComment" rows="3" value={data.buddySelectionComment} onChange={e => setData({...data, buddySelectionComment:e.target.value})} disabled={isBusy}  />
          </p>

          {validationError}
          <br />

          <div className="prepaid-form-submit">
            <button onClick={submit} disabled={isBusy}>
              Senden
            </button>
            {isBusy ? <Loading /> : null}
          </div>
        </div>
      </>
    )
  }
  return (
    <>
      <Header audience="adventurer" fixed={false} key="header"/>
      <Block id="surprise-prepaid" key="content" isMain={true}>
        {content}
      </Block>
      <Footer key="footer" hideExplanation={true} />
    </>
  )
}

/**
 * validates the payload
 * @param {*} payload 
 */
function validate(payload) {
  const isRequired = v => v.trim().length > 0
  const isEmail = v => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(v).toLowerCase());
  } 

  let emailFields = ["adventurerEmail", "buddyEmail"];
  let requiredFields = ["adventurerName", "adventurerEmail", "adventurerPhone", "buddyName", "buddyEmail", "regionCode", "regionName"];
   
  let invalidFields = [];
  emailFields.forEach(f => {
    if(!isEmail(payload[f])) invalidFields.push(f);
  })
  requiredFields.forEach(f => {
    if(!isRequired(payload[f])) invalidFields.push(f);
  })
  
  /*
  let emailValid = emailFields.reduce((prev, curr) => {
    return prev && isEmail(curr)
  }, true)
  let requiredValid = requiredFields.reduce((prev, curr) => {
    return prev && isRequired(curr)
  }, true)

  return requiredValid && emailValid
  */
  return { valid:invalidFields.length === 0, invalidFields }
}