import React, {Component} from 'react'

import './Loader.scss'

class Loader extends Component {
  render() {
    return (
      <div {...this.props} className="appentura-loader">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
      </div>
    )
  }
}

export default Loader