import React, {useState, useEffect} from "react"

import {Grid, Select, Radio} from 'semantic-ui-react'

import "../Ordered.scss"

function Transport({isHomeDelivery, isOnlineEvent, onChangeTransportOption, onChangeGaCount, onChangeHtCount, country}) {
  
  // state
  const [transportOption, setTransportOption] = useState('oeffentlicher Verkehr')
  const [gaCount, setGaCount] = useState(0)
  const [htCount, setHtCount] = useState(0)
  const [countOptions, setCountOptions] = useState([]) // we use this in the ticket count select dropdowns

  // mount
  useEffect(() => {
    const cos = [];
    for (let i = 0; i <= 8; i++) {
      cos.push({ value: i, text: i + '' });
    }
    setCountOptions(cos)
  }, [])
  

  // transport option was changed
  const onTransportOptionSelected = (e, { name, value, checked }) => {
    setTransportOption(value)
    onChangeTransportOption(value)
  }

  // number of GAs was selected
  const onGaCountSelected = (e, { name, value, checked }) => {
    setGaCount(value)
    onChangeGaCount(value)
  }

  // number of HTs was selected
  const onHtCountSelected = (e, { name, value, checked }) => {
    setHtCount(value)
    onChangeHtCount(value)
  }

  // user can only select the number of HT and GA tickets if he selected public transport
  let ticketCounts = null
  if(transportOption === 'oeffentlicher Verkehr' && (country || "ch").toLowerCase() === "ch") {
    ticketCounts = (
      <Grid.Row>
        <Grid.Column>
          <p>Welche ÖV-Abos besitzen die Teilnehmer? (spezielle Abos bitte im nächsten Feld angeben)</p>
        </Grid.Column>
        <Grid.Column>
          <span style={{paddingRight:"8px"}}>GA</span>
          <Select 
            name='gaCount'
            compact
            options={countOptions}
            value={gaCount}
            onChange={onGaCountSelected}
            className='transportSelection'
          />
          <span style={{paddingRight:"8px", paddingLeft:"8px"}}>HT</span>
          <Select 
            name='htCount'
            compact
            options={countOptions}
            value={htCount}
            onChange={onHtCountSelected}
            className='transportSelection'
          />
        </Grid.Column>
      </Grid.Row>
    )
  } 

  // home delivery or online event? no transport options need to be selected
  if(isHomeDelivery || isOnlineEvent) {
    return null
  }

  // render
  return <>
    <div className='title'><h2>Transportmittel</h2></div>
    <Grid.Row>
      <Grid.Column>
        <p>Welche Transportmöglichkeit bevorzugst du?</p>
      </Grid.Column>
      <Grid.Column>
        <Radio 
          label='öffentlicher Verkehr'
          value="oeffentlicher Verkehr"
          checked={transportOption === 'oeffentlicher Verkehr'}
          name="transportOption"
          onChange={onTransportOptionSelected}
        />
        <span style={{paddingRight:"8px"}}></span>
        <Radio 
          label='eigenes Auto'
          value="eigenes Auto"
          checked={transportOption === 'eigenes Auto'}
          name="transportOption"
          onChange={onTransportOptionSelected}
        />
      </Grid.Column>
    </Grid.Row>
    {ticketCounts}
  </>
}

export default Transport


/*
        {state.transportOption === 'oeffentlicher Verkehr' &&
          
          */