import moment from 'moment';
import 'moment/locale/de-ch';

moment.locale('de-ch');

export function ToShortDate(utc) {
    return moment(utc).format("L");
}

export function ToShortTime(utc) {
    return moment(utc).format("LT");
}

export function Parse(utc) {
    return moment(utc);
}

export function DurationInHours(minutes) {
    let half_hours = Math.round(minutes / 30.0, 1)
    let hours = half_hours / 2
    return hours
}