import React from 'react';

import Block from './../components/Block'

class NotFound extends React.Component {

  render() {
    return (
      <Block id="not-found" key="content" isMain={true}>
        <h1>Abenteuer nicht gefunden</h1>
        <p>Hier gibt es leider nichts zu sehen, um zu deiner Überraschung zu gelangen musst du den Link öffnen oder QR-Code auf deinem Gutschein verwenden.</p>
      </Block>
    );
  }
}

export default NotFound;