import React from "react"
import PropTypes from "prop-types"

import Modal from "./Modal"

import "./ModalSelfieDone.scss"

class ModalSelfieDone extends React.Component {
  render() {
    return (
      <Modal visible={true} title="Foto hochgeladen" onClose={this.onClose}>
        <div id="modal-selfie-done" className="block">
          <div className="block-inner">
            <div id="modal-selfie-done-info">
              <p>
                Dein Foto wurde hochgeladen.
              </p>
              <p>
                Du kannst es in deiner Timeline anschauen.
              </p>
            </div>
            <div id="modal-selfie-done-console">
              <button className="green" data-marketing="no" onClick={this.onClose}>Anweisungen anzeigen</button>
              <button className="green" data-marketing="yes" onClick={this.onShowTimeline}>Timeline anzeigen</button>
            </div>
          </div>

        </div>
      </Modal>
    )
  }

  onClose = (e) => {
    if(e.preventDefault) {
      e.preventDefault()
    }
    this.props.onClose({showTimeline:false})
  }

  onShowTimeline = () => {
    this.props.onClose({showTimeline:true})
  }
}

ModalSelfieDone.propTypes = {
  onClose:PropTypes.func.isRequired
}

export default ModalSelfieDone