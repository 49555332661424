import React from "react"

// subviews
import Timeline from '../components/Timeline'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Error from "../components/Error"
import {trackPageview} from "../utils/tracking"

// styling
import './Ticker.scss'


class Ticker extends React.Component {
  constructor(props) {
    super(props)

    // get live id from url
    let live_id = props.match.params.id

    // No id? no soup!
    // TODO: #content #visuals ... the error message needs to be defined / designed
    let error = null
    if(!live_id) {
      error = {
        title:"Keine Live Id",
        message:"Leider habe ich keine Live-Id erhalten ... ohne die geht unglücklicherweise gar nichts ... tut mir leid :("
      }
    }

    this.state = {
      live_id:live_id,
      error:error
    }
  }

  componentDidMount() {
    trackPageview(`/v/${this.state.live_id}`, 'watcher')
  }

  render() {
    let content = null
    if(this.state.error) {
      content = <Error title={this.state.error.title} message={this.state.error.message} />
    }
    else {
      content = <Timeline liveId={this.state.live_id} audience="watcher"/>
    }
    return (
      <div id="ticker">
        <Header audience="watcher" />
        {content}
        <Footer audience="watcher" />
      </div>
    )
  }
}

export default Ticker
