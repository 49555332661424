import React from 'react';
import PropTypes from 'prop-types'

// components
import ContactBlock from './components/ContactBlock'
import Block from './../../components/Block'
import Header from './../../components/Header'
import Footer from './../../components/Footer'

class Blocked extends React.Component {


  render() {
    const adventure = this.props.adventure;

    let content = (
      <Block id="surprise-blocked" key="content" isMain={true}>
        <h1>Hallo {adventure.RecieverRealFirstName || adventure.RecieverName}</h1>
        <p>
          Willkommen bei deiner persönlichen Überraschung, kreiert und geschenkt von {adventure.ReserverName}. Leider kann deine Überraschung momentan nicht eingelöst werden.<br />
          Mögliche Gründe dafür sind:
        </p>
        <ul>
          <li>das Ablaufdatum wurde überschritten</li>
          <li>ausstehende Zahlung der Überraschung</li>
          <li>es wurde eine Unregelmässigkeit festgestellt</li>
        </ul>

        <ContactBlock adventure={adventure} />

      </Block>
    )

    return [
      <Header audience="adventurer" fixed={false} key="header"/>,
      content,
      <Footer key="footer" />
    ]

  }
}

Blocked.propTypes = {
  adventure:PropTypes.object.isRequired
}

export default Blocked