import React from "react"
import PropTypes from "prop-types"

import "./Map.scss"

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.isMapInitialized = false
    this.hasStepChanged = false
  }

  componentDidMount() {
    this.init(this.props.step)
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.step !== this.props.step) {
      this.hasStepChanged = true
      this.init(nextProps.step)
    }
  }



  render() {
    const step = this.props.step

    return (
      <div className="c-map">
        <a className="link" href={'https://www.google.com/maps/dir/' + step.StartCoordinates.Lat + ',' + step.StartCoordinates.Lng + '/' + step.EndCoordinates.Lat + ',' + step.EndCoordinates.Lng} target="_blank" rel="noopener noreferrer">
          <div>in Google Maps öffnen</div>
        </a>
        <div ref="map" className="map"></div>
      </div>
    )
  }

  init(step) {
    if (window.google && window.google.maps) {
      this.initMap(step)
    } else {
      window.addEventListener("googleMapsLoaded", this.initMap);
    }
  }

  initMap(step) {
    const adventure = this.props.adventure

    const google = window.google

    if (step.StartCoordinates && step.EndCoordinates) {
      if (this.isMapInitialized && !this.hasStepChanged) {
        return
      }

      this.isMapInitialized = true

      let latlngStart = new google.maps.LatLng(step.StartCoordinates.Lat, step.StartCoordinates.Lng);
      let latlngEnd = new google.maps.LatLng(step.EndCoordinates.Lat, step.EndCoordinates.Lng);

      let bounds = new google.maps.LatLngBounds();
      bounds.extend(latlngStart);
      bounds.extend(latlngEnd);


      let directionsService = new google.maps.DirectionsService();
      let directionsDisplay = new google.maps.DirectionsRenderer();
      let myOptions = {
        zoom: 14,
        center: latlngStart,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false
      };

      let map = new google.maps.Map(this.refs.map, myOptions);
      map.fitBounds(bounds);


      directionsDisplay.setMap(map);
      directionsDisplay.setPanel(document.getElementById("directionsPanel"));

      // if there is a transport option Auto - use car travel mode
      let isCar = adventure.TransportOptions && adventure.TransportOptions.find(x => { return x.indexOf("Auto") >= 0 })

      let request = {
        origin: latlngStart,
        destination: latlngEnd,
        travelMode: step.TravelMode ? google.maps.TravelMode[step.TravelMode] : (isCar ? google.maps.TravelMode.DRIVING : google.maps.DirectionsTravelMode.TRANSIT)
      };

      directionsService.route(request, (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
        } else {
          if (status === 'ZERO_RESULTS') {
            alert('No route could be found between the origin and destination.');
          } else if (status === 'UNKNOWN_ERROR') {
            alert('A directions request could not be processed due to a server error. The request may succeed if you try again.');
          } else if (status === 'REQUEST_DENIED') {
            alert('This webpage is not allowed to use the directions service.');
          } else if (status === 'OVER_QUERY_LIMIT') {
            alert('The webpage has gone over the requests limit in too short a period of time.');
          } else if (status === 'NOT_FOUND') {
            alert('At least one of the origin, destination, or waypoints could not be geocoded.');
          } else if (status === 'INVALID_REQUEST') {
            alert('The DirectionsRequest provided was invalid.');
          } else {
            alert("There was an unknown error in your request. Requeststatus: \n\n" + status);
          }
        }
      });
    }
  }
}

Map.propTypes = {
  step:PropTypes.object.isRequired,
  adventure:PropTypes.object.isRequired
}

export default Map