import React from 'react';

import Block from './../components/Block'

class Home extends React.Component {

  render() {
    return (
      <Block id="home" key="content" isMain={true}>
        <h1>Appentura Erlebnisplattform</h1>
        <p>
          Hier gibt es leider nichts zu sehen.
        </p>
        <p>
          Um zu deiner Überraschung zu gelangen musst du den Link öffnen oder den QR-Code auf deinem Gutschein verwenden.
        </p>
      </Block>
    );
  }
}

export default Home;