import React from "react"
import PropTypes from "prop-types"

import Event from "./Event"

import "./Announcement.scss"

class Announcement extends React.Component {
  render() {
    let text = this.props.event.text
    let title = `Appentura`

    return (
      <Event event={this.props.event} className="event-announcement" title={title} deletable={false} onReaction={this.props.onReaction} audience={this.props.audience}>
        <div className="announcement-text">
          "{text}"
        </div>
      </Event>
    )
  }
}

Announcement.propTypes = {
  about: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onReaction: PropTypes.func.isRequired,
  audience:PropTypes.oneOf(["adventurer", "watcher"]).isRequired
}

Announcement.defaultProps = {
  audience:"adventurer"
}

export default Announcement