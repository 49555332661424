import React from 'react';
import PropTypes from 'prop-types'
import * as DateTime from '../../utils/DateTime'

// components
import Block from './../../components/Block'
import Header from './../../components/Header'
import Footer from './../../components/Footer'
import PreparingMessage from "./PreparingMessage"

class Scheduled extends React.Component {

  render() {
    const adventure = this.props.adventure;

    const date = DateTime.ToShortDate(adventure.UserStartTime)
    const time = DateTime.ToShortTime(adventure.UserStartTime)
    const durationInHours = DateTime.DurationInHours(adventure.EstimatedDuration)

    let placeAndTime
    if(adventure.IsHomeDelivery) {
      placeAndTime = <p>Wir werden dich am <b>{date}</b> um ca. <b>{time} Uhr</b> an folgendem Ort überraschen: <b>{adventure.UserStartLocationText}</b>.</p>
    }
    else {
      placeAndTime = <p>Als Startort hast du <b>{adventure.UserStartLocationText}</b> gewählt. Dort wird deine Überraschung am <b>{date}</b> um ca. <b>{time} Uhr</b> starten. Die Zeit kann aus organisatorischen Gründen noch leicht ändern.
      Rechne mit einer Dauer von ungefähr {durationInHours} Stunden für deine Überraschung.</p>
    }

    let content = (
      <Block id="surprise-scheduled" key="content" isMain={true}>
        <PreparingMessage isHomeDelivery={adventure.IsHomeDelivery} isOnlineEvent={adventure.IsOnlineEvent} />
        <h1>Wir sind dabei, deine persönliche Überraschung vorzubereiten.</h1>
        {placeAndTime}
        <p>
          Sämtliche Informationen zu deiner Überraschung findest du immer unter dieser Adresse. Du kannst sie speichern oder auch jederzeit über deinen Gutschein aufrufen.
        </p>

        <p>
          Du erhältst demnächst eine Mail von uns, wenn es weiter geht.
        </p>

        <p>
          Bis bald!<br />
          Dein Team Appentura
        </p>
      </Block>
    );

    return [
      <Header audience="adventurer" fixed={false} key="header"/>,
      content,
      <Footer key="footer" />
    ]

  }
}

Scheduled.propTypes = {
  adventure:PropTypes.object.isRequired
}

export default Scheduled;