import React from "react"

// surpriseviews
import Block from './Block'

// styling and images
import "./Footer.scss"
import PropTypes from "prop-types";

// Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGift, faMapLocationDot, } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'
// import { fabFacebook } from '@fortawesome/brands'

class Footer extends React.Component {
  render() {
    let contact = this.render_contact()
    let explanation = this.props.hideExplanation ? null : this.render_explanation()
    let buy_cta = this.render_buy_cta()
    return ([explanation, buy_cta, contact])
  }

  render_contact() {
    // let render_icon = (alt, icon, url) => {
    //   return (
    //     <div className="social-icon">
    //       <a href={url} target="_blank" rel="noopener noreferrer">
    //         <img src={icon} alt={alt} />
    //       </a>
    //     </div>
    //   )
    // }
    return (
      <Block id="footer-contact" key="footer-contact">
        <div className="social-icons">
          <div className="icon">
            <a href="https://www.facebook.com/appentura" target='_blank' rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </div>
          <div className="icon">
          <a href="https://www.instagram.com/appentura/" target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          </div>
          <div className="icon">
          <a href="https://twitter.com/appentura" target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          </div>
        </div>
        <div className="contact">
          <a href='https://www.appentura.ch' target='_blank' rel="noopener noreferrer"><p>www.appentura.ch</p></a>
          <a href="mailto:hello@appentura.ch">hello@appentura.ch</a><br></br><a href='tel:+41 31 511 21 32'>+41 31 511 21 32</a>
        </div>
      </Block>
    )
  }

  render_explanation() {
    return (
      <Block id="footer-explanation" key="footer-explanation">
        <div className="title">Wie funktioniert Appentura?</div>
        <div className="icon">
          <FontAwesomeIcon icon={faGift} />
        </div>
        <div className="text">
          Du kreierst aus verschiedenen Aktivitäten ein einzigartiges Erlebnisgeschenk.
        </div>
        <div className="icon">
        <FontAwesomeIcon icon={faMapLocationDot} />
        </div>
        <div className="text">
          Die Beschenkten werden per Smartphone Schritt für Schritt geführt und erfahren erst vor Ort, was sie erleben dürfen.
        </div>
      </Block>
    )
  }

  render_buy_cta() {
    if(this.props.audience === 'adventurer') {
      return null
    }
    return (
      <Block id="footer-buy-cta" key="footer-buy-cta">
        <a href="https://appentura.ch/shop" target="_blank" rel="noopener noreferrer">Schenke ein Überraschungs-Erlebnis</a>
      </Block>
    )
  }
}

Footer.propTypes = {
  audience:PropTypes.oneOf(["adventurer", "watcher"]).isRequired,
}

Footer.defaultProps = {
  audience:"adventurer"
}
export default Footer