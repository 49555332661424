import React from "react"
import PropTypes from "prop-types"

import Modal from "./Modal"

import "./ModalError.scss"

class ModalError extends React.Component {
  constructor(props) {
    super(props)
    this.onClickClose = this.onClickClose.bind(this)
  }

  render() {
    return (
      <Modal visible={true} title={this.props.title} onClose={this.onClickClose}>
        <div id="modal-help" className="block">

            <div className="Message">
              {this.props.message}
            </div>

            <div className="console">
              <button className="red" onClick={this.onClickClose}>schliessen</button>
            </div>

        </div>

      </Modal>
    )
  }

  onClickClose(e) {
    if(e.preventDefault) {
      e.preventDefault()
    }
    this.props.onClose(this.props.data)
  }
}

ModalError.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.any
}

export default ModalError
