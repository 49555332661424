import React from 'react'
import PropTypes from "prop-types"
import * as DateTime from '../utils/DateTime'

import "./Countdown.scss"

class Countdown extends React.Component {

  constructor(props) {
    super(props)

    this.timer = null
    this.clock = null // ref to the div that displays the timer
  }

  componentDidMount() {
    //this.clock = document.getElementById("countdown");
    this.timer = setInterval(this.setTime, 1000);
    this.setTime();
  }

  componentWillUnmount() {
    clearInterval(this.timer);    
  }

  render() {
    return (
      <div>
        <p>
          Los geht's in:
        </p>
        <div id="countdown" ref={(div) => this.clock = div}></div>
      </div>
    );
  }


  getTimeRemaining(endtime) {
    let t = endtime - new Date();
    if(t <= 0) {
      t = 0
    }
    let seconds = Math.floor((t / 1000) % 60);
    let minutes = Math.floor((t / 1000 / 60) % 60);
    let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    let days = Math.floor(t / (1000 * 60 * 60 * 24));

    return {
      'total': t,
      'days': days,
      'hours': hours,
      'minutes': minutes,
      'seconds': seconds
    };
  }

  setTime = () => {
    var t = this.getTimeRemaining(DateTime.Parse(this.props.time));


    if(this.clock) {
      this.clock.innerHTML = '<table><tr><td> ' + t.days + '</td><td> ' + t.hours + '</td><td> ' + t.minutes + '</td><td> ' + t.seconds + '</td></tr><tr><td>Tagen</td><td>Stunden</td><td>Minuten</td><td>Sekunden</td></tr></table>';
    }

    // if the timer is done we stop the timer and inform listeners
    if (t.total <= 0) {
      clearInterval(this.timer);
      this.props.onCountdownOver()
    }
  }


}

Countdown.propTypes = {
  onCountdownOver:PropTypes.func.isRequired
}

export default Countdown;