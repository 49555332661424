import React from "react"
import PropTypes from "prop-types"
import Event from "./Event"

import "./Selfie.scss"

class Selfie extends React.Component {

  render() {
    let title = `${this.props.about.for}`
    return (
      <Event className="event-selfie" title={title} event={this.props.event} audience={this.props.audience} onDelete={this.props.onDelete} deletable={true} onReaction={this.props.onReaction}>
        <div className="image" data-selfie-image={this.props.event.image}>
          <img src={this.props.event.image} alt="look how lovely this looks!" />
        </div>
      </Event>
    )
  }

}

Selfie.propTypes = {
  about: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReaction: PropTypes.func.isRequired,
  audience:PropTypes.oneOf(["adventurer", "watcher"]).isRequired
}

Selfie.defaultProps = {
  audience:"adventurer"
}

export default Selfie