import React from 'react';
import PropTypes from 'prop-types'

// components
import ContactBlock from './components/ContactBlock'
import Block from './../../components/Block'
import Header from './../../components/Header'
import Footer from './../../components/Footer'

class Archived extends React.Component {

  render() {
    const adventure = this.props.adventure;

    let content = (
      <Block id="surprise-archived" key="content" isMain={true}>
        <h1>Hallo {adventure.RecieverRealFirstName || adventure.RecieverName}</h1>
        <p>
          Diese Überraschung wurde archiviert und ist nicht mehr zugänglich.
        </p>     
        <ContactBlock adventure={adventure} />
      </Block>
    )

    return [
      <Header audience="adventurer" fixed={false} key="header"/>,
      content,
      <Footer key="footer" />
    ]
  }
}

Archived.propTypes = {
  adventure:PropTypes.object.isRequired
}

export default Archived