import React from "react"
import PropTypes from "prop-types"

import Event from "./Event"

import "./Travel.scss"

class Travel extends React.Component {
  render() {
    let title
    let text
    // `car`, `train`, `bus`, `tram`, `bike`, `walk`
    switch(this.props.event.means) {
      case "car":
        title = `Mit dem Auto unterwegs`
        text = `Wohin die Fahrt wohl geht?`
        break
      case "public": // legacy
        title = `Mit den ÖV unterwegs`
        text = `Wohin die Fahrt wohl geht?`
        break
      case "bike":
        title = `Mit dem Velo unterwegs`
        text = `Wohin die Fahrt wohl geht?`
        break
      case "bus":
        title = `Mit dem Bus unterwegs`
        text = `Wohin die Fahrt wohl geht?`
        break
      case "tram":
        title = `Mit dem Tram unterwegs`
        text = `Wohin die Fahrt wohl geht?`
        break
      case "train":
        title = `Mit dem Zug unterwegs`
        text = `Wohin die Fahrt wohl geht?`
        break
      case "walking":
      case "walk":
        title = `Zu Fuss unterwegs`
        text = `Wohin der Spaziergang wohl führt? `
        break
      default:
        title = `Unterwegs`
        text = `Wohin es wohl geht?`
        break
    }

    return (
      <Event event={this.props.event} className="event-travel" title={title} onReaction={this.props.onReaction} audience={this.props.audience}>
        <div className="travel-text">
          {text}
        </div>
      </Event>
    )
  }
}

Travel.propTypes = {
  about: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onReaction: PropTypes.func.isRequired,
  audience:PropTypes.oneOf(["adventurer", "watcher"]).isRequired
}

Travel.defaultProps = {
  audience:"adventurer"
}

export default Travel