import React, {useState} from "react"

import {Grid} from 'semantic-ui-react'
import PlaceInput from "../components/PlaceInput"
import AvailableAreas from "./AvailableAreas"

function Location({validate, onChange, adventure}) {
  // state
  const [location, setLocation] = useState(null)
  const [isHomeDelivery] = useState(adventure.IsHomeDelivery)
  const [isOnlineEvent] = useState(adventure.IsOnlineEvent)
  
  // selected location changed
  const onSelectLocation = (value) => {
    setLocation(value)
    onChange(value)
  }

  // the displayed message depends on whether this is a home delivery or standard suprise
  let message
  let errorMessage
  if(isHomeDelivery) {
    message = <p>Du wirst von uns zu Hause überrascht. Bitte gib uns deine genaue Adresse an.</p>
    errorMessage = "Wohin sollen wir deine Überraschung bringen?"
  }
  else if(isOnlineEvent) {
    message = <p>Du wirst von uns zu Hause überrascht. Bite gib uns deine Adresse an.</p>
    errorMessage = "Wo soll deine Überraschung statt finden?"
  }
  else {
    message = <p>Wo soll deine Überraschung starten? Gib bitte eine möglichst genaue Adresse an.</p>
    errorMessage = "Wo soll die Überraschung starten?"
  }


  // render
  let placeholderText = isHomeDelivery || isOnlineEvent ? "deine Adresse" : "Startpunkt, deine Adresse"
  return <>
    <div className='title'><h2>{isHomeDelivery || isOnlineEvent ? "Ort" : "Startort"}</h2></div>
    <AvailableAreas adventure={adventure} />
    <Grid.Row>
      <Grid.Column>
        {message}
      </Grid.Column>
      <Grid.Column>
        <div className="placeinput">
          <PlaceInput placeholder={placeholderText}
                      value={location || ''}
                      onSelect={onSelectLocation}
                      country={(adventure.Country.toUpperCase())}
          />
        </div>
        {validate && !location && <p className="error">{errorMessage}</p>}
      </Grid.Column>
    </Grid.Row>
  </>
}

export default Location