const firebase_config_production = {
  apiKey: "AIzaSyCrpxJzMP6Uni6hcqbpNBm_U6pp7tCvf5M",
  authDomain: "appenturaticker.firebaseapp.com",
  databaseURL: "https://appenturaticker.firebaseio.com",
  projectId: "appenturaticker",
  storageBucket: "appenturaticker.appspot.com",
  messagingSenderId: "628848148612",
  message_text:'',
  message_send_enabled:false,
  selfie_data:null
}

// https://console.firebase.google.com/project/appenturatickerstaging/settings/cloudmessaging/
const firebase_config_staging = {
  apiKey: "AIzaSyArCJyIqsLSNZ7aKWZE1fd6LYzz5m1JtFs",
  authDomain: "appenturatickerstaging.firebaseapp.com",
  databaseURL: "https://appenturatickerstaging.firebaseio.com",
  projectId: "appenturatickerstaging",
  storageBucket: "appenturatickerstaging.appspot.com",
  messagingSenderId: "586073869851",
  message_text:'',
  message_send_enabled:false,
  selfie_data:null
}

// https://console.firebase.google.com/project/appentura-development/settings/cloudmessaging/
const firebase_config_development = {
  apiKey: "AIzaSyA0lbO2VCdvcjWJ0zi5e8E0KpBBxQVBnDs",
  authDomain: "appentura-development.firebaseapp.com",
  databaseURL: "https://appentura-development.firebaseio.com",
  projectId: "appentura-development",
  storageBucket: "appentura-development.appspot.com",
  messagingSenderId: "854559212863",
  message_text:'',
  message_send_enabled:false,
  selfie_data:null
}

const config = {
  development: firebase_config_development,
  staging: firebase_config_staging,
  production: firebase_config_production
}

export default config