import React, {useState, useEffect} from 'react'
import {ApiBuddy as Api} from "../../utils/api";

// components
import Block from './../../components/Block'
import Header from './../../components/Header'
import Footer from './../../components/Footer'
import Loading from './../../components/Loading'

import "./Prepaid.scss"

export default function BuddySelected({adventureId}) {
  // state
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  // mount
  useEffect(() => {
    const load = async() => {
      const result = await Api("receiver", "load", {adventureId:adventureId});
      if(!result.success) {
        setError(result.error);
      }
      else {
        setData({adventure:result.adventure, regions:result.regions});
      }
    }
    load();
  }, [adventureId])

  // make render content
  let content = null
  if(error) {
    content = <>
      <h1>{error.title}</h1>
      <div className="prepaid-info">
        <p>{error.message}</p>
      </div>
      <div className="prepaid-info">
        <p>Falls das nicht stimmt, nimm bitte mit uns Kontakt auf.</p>
      </div>
    </>
  }
  else if(!data) {
    content = <Loading />
  }
  else {
    const adventure = data.adventure;
    const regionName = data.regions.find(region => region.code === data.adventure.regionCode) || "Region Bern";
    content = <>
      <h1>Deine Überraschung ist in Vorbereitung</h1>
      <p>Du hast bereits festgelegt, wer den Inhalt deiner Überraschung bestimmen soll, wo die Überraschung stattfindet und ob du sie alleine oder in Begleitung erleben möchtest.</p>
      <h3>Deine Überraschung</h3>
      <p>Deine Überraschung findet in der {regionName} für {adventure.participantCount || 1} Personen statt.</p>
      <h3>Vertrauensperson, die den Inhalt deiner Überraschung bestimmen darf</h3>
      <ul>
        <li>Name: {adventure.buddyName}</li>
        <li>Mail-Adresse: {adventure.buddyEmail}</li>
      </ul>
      <h3>Deine Kontaktinformationen</h3>
      <ul>
        <li>Name: {adventure.adventurerName}</li>
        <li>Mail-Adresse: {adventure.adventurerEmail}</li>
        <li>Telefonnummer: {adventure.adventurerPhone}</li>
        <li>dein Kommentar: {adventure.buddySelectionComment}</li>
      </ul>
    </>
  }

  // render
  return (
    <>
      <Header audience="adventurer" fixed={false} key="header"/>
      <Block id="surprise-prepaid" key="content" isMain={true}>
        {content}
      </Block>
      <Footer key="footer" hideExplanation={true} />
    </>
  )
}
