import React, {useState} from "react"

import {
  Grid
} from 'semantic-ui-react'
import DateInput from "../components/DateInput"

function StartTime({onChange, validate, redeemOffsetDays}) {
  // state
  const [date, setDate] = useState(null)

  // event handler
  const onSelectDate = (e, data) => {
    if(e && e.preventDefault) {
      e.preventDefault()
    }
    setDate(data.value)
    onChange(data.value)
  }

  // render
  return (
    <Grid.Row>
      <Grid.Column>
        <p>
          Wann soll deine Überraschung stattfinden? Losgehen kann es frühestens in {redeemOffsetDays} Tagen.
        </p>
      </Grid.Column>
      <Grid.Column>
        <DateInput 
          id='date'
          name='date'
          placeholder='Datum'
          value={date || ''}
          offsetDays={redeemOffsetDays - 1}
          onChange={onSelectDate} 
        />
        {validate && !date && <p className="error">Wann soll die Überraschung stattfinden? (Losgehen kann es frühestens in {redeemOffsetDays} Tagen)</p>}
      </Grid.Column>
    </Grid.Row>
  )
}

export default StartTime