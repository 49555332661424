import React from "react"
import PropTypes from "prop-types"

import Event from "./Event"

import "./Review.scss"

class Review extends React.Component {

  render() {
    let title = `Die Überraschung wurde bewertet`



    let starCount = Number(this.props.event.stars_overall)
    let stars = []
    for(let i=0; i<5; i+=1) {
      let starClass = starCount >= i+1 ? 'full' : 'empty'
      let star = starClass === 'full' ? '★' : '☆'
      stars.push(<span key={i} className={`star ${starClass}`}>{star}</span>)
    }

    let text = null
    if((this.props.event.text || '').trim().length > 0) {
      text = <div className="review-text" key="text">{`"${this.props.event.text}"`}</div>
    }

    return (
      <Event event={this.props.event} className="event-review" title={title} onReaction={this.props.onReaction} audience={this.props.audience}>
        <div className="review-stars" key="stars">{stars}</div>
        {text}
      </Event>
    )

    /* review text no longer displayed, for safekeeping:
    let text = (this.props.event.text || '').trim()
    {text.length > 0 ? <div className="review-text" key="review-text">{text}</div> : null}
     */
  }
}

Review.propTypes = {
  about: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onReaction: PropTypes.func.isRequired
}

Review.defaultProps = {
  audience:"adventurer"
}

export default Review