import React from 'react';
import PropTypes from 'prop-types'

// components
import TooEarly from './components/TooEarly'
import Header from './../../components/Header'
import Footer from './../../components/Footer'

class Accepted extends React.Component {

  render() {
    return [
      <Header audience="adventurer" fixed={false} key="header"/>,
      <TooEarly title="Deine Überraschung ist bereit" adventure={this.props.adventure} onCountdownOver={()=>{console.log('timer is done')}} key="tooearly"/>,
      <Footer key="footer" />
    ]
  }
}

Accepted.propTypes = {
  adventure:PropTypes.object.isRequired
}

export default Accepted;