import React from 'react'
import PropTypes from 'prop-types'

// components
import Block from './../../components/Block'
import Header from './../../components/Header'
import Footer from './../../components/Footer'
import MemoryLane from './../../components/MemoryLane'
// styling & Images
import Images from "../../images";
import "./Reviewed.scss";

class Reviewed extends React.Component {

  render() {
    const adventure = this.props.adventure;

    // good rating = none of the rating below threshold
    let hasGoodRating = true;
    const threshold = 4;
    const rating = adventure.Rating;
    if ((rating.Rating && rating.Rating < threshold) || (rating.Rating2 && rating.Rating2 < threshold) || (rating.Rating3 && rating.Rating3 < threshold)) {
      hasGoodRating = false;
    }

    // pre-render
    let content = null;
    if (hasGoodRating) {
      content = (
        <Block id="surprise-reviewed" key="content" isMain={true}>
          <div className="pato-duck-content">
            <p>So schön hat dir die Überraschung gefallen. <br /> Solche Feedbacks bringen uns zum Tanzen, vielen Dank!</p>
            <img src={Images.gifs.patoDuck} alt="pato duck dancing" className='duck'/>
            <p>Lass uns noch jemanden überraschen!<br /> 
            Mit dem Code <span className='code'>ALE6BTF9</span> bekommst du 10% Rabatt.</p>
            <p>Viel Freude beim Schenken</p>
            <a href='https://appentura.ch/assistant' rel="noopener noreferrer" className='button'>Jetzt Überraschen</a>
          </div>
        </Block>
      )
    }
    else {
      content = (
        <Block id="surprise-reviewed" key="content" isMain={true}>
          {adventure.PromoName ?
            <p>Wir hoffen dir hat die Überraschung gefallen!</p> :
            <p>Vielen Dank für deinen Kommentar und dein Vertrauen!</p>
          }

          <p>
            <a href='https://appentura.ch/assistant' className='button'>Neue Überraschung gefällig?</a>
          </p>
        </Block>
      )
    }

    return [
      <Header audience="adventurer" fixed={false} key="header" />,
      content,

      <Footer key="footer" hideExplanation={hasGoodRating ? true : false} />
    ]
  }
}

Reviewed.propTypes = {
  adventure: PropTypes.object.isRequired
}

export default Reviewed