import config from "./../config"

let devlog

if(["development", "staging"].includes(config.environment)) {
  devlog = console.log.bind(window.console)
}
else {
  devlog = () => {}
}

export default devlog

// based on: https://stackoverflow.com/a/32928812