import React, {useState, useEffect} from 'react'
import superagent from 'superagent'
import config from './../../config'

import "./PreparingMessage.scss"

function PreparingMessage({isHomeDelivery, isOnlineEvent}) {
  // state
  const [preparingMessage, setPreparingMessage] = useState({show:false})
  // mount
  useEffect(() => {
    load()
  }, [])

  // load message
  const load = async() => {
    try {
      let key = "PREPARING_MESSAGE";
      if(isHomeDelivery) {
        key = "PREPARING_MESSAGE_HOMEDELIVERY";
      }
      else if(isOnlineEvent) {
        key = "PREPARING_MESSAGE_ONLINEEVENT"
      }
      let result = await superagent.get(`${config.api.root}/surpriseproperties/${key}`)
      let preparingMessage = result.body.value || {show:false}
      setPreparingMessage(preparingMessage)
    }
    catch(err) {
      console.error(err)
    }
  }

  // render
  if(preparingMessage.show) {
    let messageLines = (preparingMessage.message || "").split("\n").map((line, index) => {
      return <p key={index}>{line}</p>
    })
    return (
      <div id="surprise-preparing-message">
        <div className="title">{preparingMessage.title}</div>
        <div className="message">{messageLines}</div>
      </div>
    )
  }
  else {
    return null
  }
}

export default PreparingMessage