import React, { Component } from 'react';
import PropTypes from "prop-types"

import {
  Input
} from 'semantic-ui-react'

import flatpickr from "flatpickr"
import { German } from "flatpickr/dist/l10n/de.js"

class DateInput extends Component {

  constructor(props) {
    super(props);

    this.state = { address: this.props.location ? this.props.location.name : '' };
  }

  componentDidMount() {
    flatpickr("#" + this.props.id, {
      dateFormat: 'd.m.Y',
      locale: German,
      disable: [(date) => {
        return date < (new Date()).setDate(new Date().getDate() + this.props.offsetDays);
      }],
      onChange: (selectedDates, dateStr, instance) => {
        this.props.onChange(null, { name: this.props.name, value: dateStr });
      }
    });
  }

  render() {   
    return (
      <Input id={this.props.id} type="date" name={this.props.name} fluid placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} />
    );
  }
}

DateInput.propTypes = {
  offsetDays:PropTypes.number
}

DateInput.defaultProps = {
  offsetDays:0
}

export default DateInput;
