import React, {useState} from "react"

import {
  Grid,
  Input
} from 'semantic-ui-react'

function Name({onChange, validate, initialValue}) {
  const [name, setName] = useState(initialValue)

  const change = (e, {name, value, checked}) => {
    setName(value)
    onChange(value)
  }

  return (
    <Grid.Row>
      <Grid.Column>
        <p>
          Als erstes möchten wir wissen wie wir dich ansprechen sollen. Wie heisst du? (Der Vorname einer Person genügt)
        </p>
      </Grid.Column>
      <Grid.Column>
        <Input 
          name='name'
          placeholder='Dein Vorname'
          fluid={true}
          value={name}
          onChange={change}
        />
        {validate && !name && <p className="error">Gib deinen Vornamen ein</p>}
      </Grid.Column>
    </Grid.Row>
  )
}

export default Name