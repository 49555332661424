import React from 'react';
import PropTypes from 'prop-types'

// components
import ContactBlock from './components/ContactBlock'
import Block from './../../components/Block'
import Header from './../../components/Header'
import Footer from './../../components/Footer'

class NotFinished extends React.Component {

  render() {
    let content = (
      <Block id="surprise-notfinished" key="content" isMain={true}>
        <h1>Willkommen zu deiner Appentura-Überraschung</h1>
        <p>Leider ist die Überraschung noch nicht vollständig erstellt, bitte kontaktiere uns um das Problem zu lösen.</p>
        <ContactBlock adventure={this.props.adventure} />
      </Block>
    )

    return [
      <Header audience="adventurer" fixed={false} key="header"/>,
      content,
      <Footer key="footer" />
    ]

  }
}

NotFinished.propTypes = {
  adventure:PropTypes.object.isRequired
}

export default NotFinished;