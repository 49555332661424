import React, { Component } from 'react'

import Loader from './Loader'

class LoaderOverlay extends Component {
  render() {
    return (
      <div style={{
        opacity: 0.5,
        background: '#000',
        width: '100%',
        height: '100%',
        zIndex: 10,
        top: 0,
        left: 0,
        position: 'fixed'
      }}>
        <Loader style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: 'auto'
        }} />
      </div>
    )
  }
}

export default LoaderOverlay