import React, { Component } from 'react';

import {
  Select
} from 'semantic-ui-react'

class TimeInput extends Component {

  timeOptions = [];
  
  constructor(props) {
    super(props);

    for (var i = 8; i <= 20; i++) {
      this.timeOptions.push({ value: i * 60, text: (i < 10 ? ('0' + i) : i) + ':00' });
    }  
  }
  
  render() {   
    return (
      <Select name={this.props.name} placeholder={this.props.placeholder} fluid options={this.timeOptions} value={this.props.value} onChange={this.props.onChange} />
    );
  }
}

export default TimeInput;
