import Analytics from "react-ga"
import config from "./../config"

// initialize service
Analytics.initialize(config.analytics.trackingId)

function trackPageview(location, audience) {
  if((audience === 'watcher' && config.analytics.trackWatcher) || (audience === "adventurer" && config.analytics.trackAdventurer)) {
    Analytics.pageview(location)
  }
}

export {trackPageview}