import React from 'react';

import {
  Button
} from 'semantic-ui-react'

class Error extends React.Component {
  
  onBack = () => {
    this.props.history.push("/s/" +  this.props.match.params.id);
  }

  render() {
    return (
      <div>
        <h1>Fehler</h1>    
        <p>Es ist ein Fehler aufgetreten. Überprüfe deine Internetverbindung und versuche die Seite neu zu laden, falls das Problem besteht kontaktiere uns bitte und wir kümmern uns sofort darum.</p>
        { this.props.match.params.id &&
          <Button onClick={this.onBack} floated="right">Zurück zur Überraschung</Button>
        }
      </div>
    );
  }
}

export default Error;