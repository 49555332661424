import React from 'react';
import PropTypes from 'prop-types'

// components
import {  
  Grid,
  Rating,
  Button,
  Form,
  TextArea
} from 'semantic-ui-react'
import Block from './../../components/Block'
import Header from './../../components/Header'
import Footer from './../../components/Footer'
import MemoryLane from './../../components/MemoryLane'


class Finished extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rating1: null,
      rating2: null,
      rating3: null,
      comment: '',
      feedback: '',
      isSubmitted: false,
      valid:false
    }

    this.onChangeRating1 = this.onChangeRating1.bind(this)
    this.onChangeRating2 = this.onChangeRating2.bind(this)
    this.onChangeRating3 = this.onChangeRating3.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.rating1 !== prevState.rating1 || this.state.rating2 !== prevState.rating2 || this.state.rating3 !== prevState.rating3) {
      let valid = this.validate()
      if(valid !== this.state.valid) {
        this.setState({valid})
      }
    }
  }

  render() {


    let content = (
      <Block id="surprise-finished" key="content" isMain={true}>
        <h1>Bevor du in Erinnerungen an dein Erlebnis schwelgst&nbsp;...</h1>
        <p style={{marginBottom: 20}}>Gib uns Feedback, wie dir deine Überraschung gefallen hat. Damit wir uns stetig weiterentwickeln und unsere Erlebnisse perfektionieren können, sind Bewertungen unglaublich wichtig.</p>

        <Grid stackable columns={2}>
          {/* 
          <Grid.Row>
            <Grid.Column width={16}>
              <Button color='facebook' onClick={this.openFacebookReview}>
                <Icon name='facebook' /> Auf Facebook bewerten
              </Button>
            </Grid.Column>
          </Grid.Row>
          */}
          <Grid.Row>
            <Grid.Column>
              Wie hat dir die Überraschung insgesamt gefallen?
            </Grid.Column>
            <Grid.Column>
              <Rating maxRating={5} clearable icon='star' size='massive' rating={this.rating1} onRate={this.onChangeRating1} />
              {this.state.isSubmitted && !this.state.rating1 && <p className="error">Wähle zwischen 1 und 5 Sterne aus</p>}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              Wie zufrieden warst Du dem Ablauf und den Instruktionen?
            </Grid.Column>
            <Grid.Column>
              <Rating maxRating={5} clearable icon='star' size='massive' rating={this.rating2} onRate={this.onChangeRating2} />
              {this.state.isSubmitted && !this.state.rating2 && <p className="error">Wähle zwischen 1 und 5 Sterne aus</p>}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              Wie gut beurteilst Du die Leistung der Anbieter?
            </Grid.Column>
            <Grid.Column>
              <Rating maxRating={5} clearable icon='star' size='massive' rating={this.rating3} onRate={this.onChangeRating3} />
              {this.state.isSubmitted && !this.state.rating3 && <p className="error">Wähle zwischen 1 und 5 Sterne aus</p>}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              Wie hat es dir gefallen? (optional, wird in deiner Timeline angezeigt)
            </Grid.Column>
            <Grid.Column>
              <Form>
                <TextArea placeholder='Deine schriftliche Bewertung ...' style={{ width: '100%' }} autoHeight value={this.state.comment} onChange={this.onChangeComment} />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              Möchtest du uns noch etwas mitteilen? (optional, dies bleibt zwischen uns)
            </Grid.Column>
            <Grid.Column>
              <Form>
                <TextArea placeholder='Deine Anregungen ...' style={{ width: '100%' }} autoHeight value={this.state.feedback} onChange={this.onChangeFeedback} />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div style={{ paddingTop: '20px', display: 'flex', justifyContent: 'center'}}>
          <Button floated="right" onClick={this.send} disabled={!this.state.valid}>Senden</Button>
        </div>

      </Block>
    )

    return [
      <Header audience="adventurer" fixed={false} key="header"/>,
      content,
      <MemoryLane liveId={this.props.adventure.LiveId}/>,
      <Footer key="footer" />
    ]
  }

  openFacebookReview = () => {
    window.open('https://www.facebook.com/appentura/reviews');
  }

  onChangeRating1 = (e, data) => { this.setState({ rating1: data.rating }); }
  onChangeRating2 = (e, data) => { this.setState({ rating2: data.rating }); }
  onChangeRating3 = (e, data) => { this.setState({ rating3: data.rating }); }
  onChangeComment = (e, data) => { this.setState({ comment: data.value}); }
  onChangeFeedback = (e, data) => { this.setState({ feedback:data.value})}

  validate() {
    const s = this.state
    return s.rating1 && s.rating2 && s.rating3
  }

  send = () => {
    this.setState({ isSubmitted: true })
    if (this.validate()) {
      this.props.onRate({
        rating1: this.state.rating1,
        rating2 : this.state.rating2,
        rating3 : this.state.rating3,
        comment : this.state.comment.trim(),
        feedback: this.state.feedback.trim()
      })
    }
  }
}

Finished.propTypes = {
  adventure:PropTypes.object.isRequired,
  onRate:PropTypes.func.isRequired
}

export default Finished;