import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import LocalStorage from "./../../utils/LocalStorage"
import anime from "animejs"

import images from "./../../images"

// Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import "./Event.scss"

class Event extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      deleteState:'default', // one of 'default', 'confirm'
      //visibility:'hidden'
    }
    this.onDelete = this.onDelete.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
    this.onDeleteAbort = this.onDeleteAbort.bind(this)
    this.onClickReaction = this.onClickReaction.bind(this)

    this.ref_container = null
  }

  componentDidMount() {
    if(this.ref_container) {
      if(this.ref_container) {
        anime({
          targets:`#event_${this.props.event.id}`,
          scale: [
            {value:0, duration:0, delay:0},
            {value:1, duration:1800, delay:0}
          ]
        })
      }
    }
  }

  render() {
    return (
      <div id={`event_${this.props.event.id}`} ref={(div) => this.ref_container = div} className={`event ${this.props.className ? this.props.className : ''}`} >
        <div className="heading">
          <div className="icon">
            {this.render_icon()}
          </div>
          <div className="title-and-timestamp">
            <div className="title" style={{marginTop: 0}}>{(this.props.title || '').trim()}</div>
            <div className="timestamp">{moment(this.props.event.when).format("D.M.YYYY - HH:mm")}</div>
          </div>
        </div>

        <div className="content">
          {this.props.children}
        </div>

        <div className="reactions-and-actions">
          <div className="reactions">
            {this.render_reaction("smiling_face_with_heart_shaped_eyes")}
            {this.render_reaction("grinning_face_with_smiling_eyes")}
            {this.render_reaction("smiling_face_with_sunglasses")}
            {this.render_reaction("astonished_face")}
            {this.render_reaction("face_savouring_delicious_food")}
          </div>
          <div className="actions">
            {this.props.deletable && this.props.audience === 'adventurer' ? this.render_action('delete') : null}
          </div>
        </div>
      </div>
    )
  }

  render_icon() {
    let src
    switch(this.props.event.what) {
      case 'start':
        src = images.events.start
        break
      case 'end':
        src = images.events.finish
        break
      case 'travel':
        switch(this.props.event.means) {
          case "car":
            src = images.events.travel.car
            break
          case "public":
          case "bus":
            src = images.events.travel.bus
            break
          case "tram":
            src = images.events.travel.tram
            break
          case "train":
            src = images.events.travel.train
            break
          case "bike":
            src = images.events.travel.bike
            break
          case "walk":
          case "walking":
          default:
            src = images.events.travel.walking
        }
        break
      case 'review':
        src = images.events.review
        break
      case 'activity':
        src = images.events.activity
        break
      case 'selfie':
        src = images.events.selfie
        break
      case 'message':
      default:
        src = images.events.message
        break
    }

    return <img src={src} alt={this.props.event.kind} />
  }

  render_reaction(name) {
    let reactions = this.props.event.reactions || {}
    let count = reactions[name] || 0
    let eventId = this.props.event.id // we need this to give the reaction a unique ID so we can use anime.js on it

    return (
      <div id={`${eventId}_${name}`} className={`reaction ${count > 0 ? 'disabled':'enabled'} ${this.props.audience}`} key={name} onClick={this.onClickReaction} data-name={name}>
        <img src={images.emoji[name]} alt={name} />
        <div className="count">{count}</div>
      </div>
    )
  }

  render_action(name) {
    let action
    switch(name) {
      case 'delete':
        action = this.render_action_delete()
        break
      default:
        action = null
        break
    }
    return action
  }

  render_action_delete() {
    if(this.state.deleteState === 'default') {
      return (
        <div className="action" key="delete" onClick={this.onDelete}>
          {/* <img src={images.actions.delete} alt="löschen" /> */}
          <FontAwesomeIcon icon={faTrash} alt="löschen" />
        </div>
      )
    }
    else {
      return (
        <div className="action-confirm" key="delete">
          <div className="question">löschen?</div>
          <div className="answer yes" onClick={this.onDeleteConfirm}>ja</div>
          <div className="answer no" onClick={this.onDeleteAbort}>nein</div>
        </div>
      )
    }

  }

  onDelete(e) {
    this.setState({...this.state, deleteState:'confirm'})
  }

  onDeleteConfirm(e) {
    let eventId = this.props.event.id

    this.props.onDelete(eventId)
    this.setState({...this.state, deleteState:'default'})
  }

  onDeleteAbort(e) {
    this.setState({...this.state, deleteState:'default'})
  }

  onClickReaction(e) {
    let eventId = this.props.event.id
    let name = e.currentTarget.dataset.name

    // user can only post a reaction once ... the LocalStorage.addReaction returns true if it was able to add the reaction
    if(LocalStorage.addReaction(eventId, name)) {
      anime({
        targets:`#${eventId}_${name}`,
        rotateY: [
          {value:360, duration:1000, delay:0, elasticity:0},
          {value:360, duration:800, delay:0, elasticity:0},
          {value:0, duration:0, delay:0, elasticity:0},
        ]
      })

      this.props.onReaction(eventId, name)
    }
    else {
      anime({
        targets:`#${eventId}_${name}`,
        translateX: [
          {value:3, duration:50, delay:0, elasticity:0},
          {value:-3, duration:50, delay:0, elasticity:0},
          {value:3, duration:50, delay:0, elasticity:0},
          {value:-3, duration:50, delay:0, elasticity:0},
          {value:0, duration:50, delay:0, elasticity:0},
        ]
      })
    }

  }

}

Event.propTypes = {
  className:PropTypes.string,
  title:PropTypes.string,
  event:PropTypes.object.isRequired,
  deletable:PropTypes.bool.isRequired,
  onDelete:PropTypes.func,
  onReaction:PropTypes.func.isRequired,
  audience:PropTypes.oneOf(["adventurer", "watcher"]).isRequired
}

Event.defaultProps = {
  deletable:false,
  audience:"adventurer"
}


export default Event