import React, {useState} from "react"

import {
  Grid
} from 'semantic-ui-react'

import TimeInput from "../components/TimeInput"

function StartTime({onChange, validate, isHomeDelivery, isOnlineEvent}) {
  // state
  const [time, setTime] = useState(null)

  // event handler
  const onChangeTime = (e, data) => {
    if(e.preventDefault) {
      e.preventDefault()
    }
    setTime(data.value)
    onChange(data.value)
  }

  // render
  let question
  if(isHomeDelivery || isOnlineEvent) {
    question = "Um welche Zeit möchtest du überrascht werden?"
  }
  else {
    question = "Um welche Zeit soll es losgehen? Die effektive Startzeit kann noch ein wenig abweichen."
  }
  return (
    <Grid.Row>
      <Grid.Column>
        <p>
          {question}
        </p>
      </Grid.Column>
      <Grid.Column>
        <TimeInput 
          name='time'
          placeholder='Zeit'
          value={time}
          onChange={onChangeTime}
        />
        {validate && !time && <p className="error">Wähle die ungefähre Startzeit</p>}
      </Grid.Column>
    </Grid.Row>
  )
}

export default StartTime