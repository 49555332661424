let googleTrackingId = "UA-66274358-6" // NOTE: this is meant to be tracking traffic at live.appentura.ch ... while tracking surprise.appentura.ch is possible, it is not the intention to do so ... at a later point we might want to do that and are going to have to decide how to set it all up then

const config = {
  development: {
    trackAdventurer:false,
    trackWatcher:false,
    trackingId: googleTrackingId
  },
  staging: {
    trackAdventurer:false,
    trackWatcher:false,
    trackingId: googleTrackingId
  }
  ,
  production: {
    trackAdventurer:false,
    trackWatcher:true,
    trackingId: googleTrackingId
  }
}

export default config