import React from 'react';
import PropTypes from 'prop-types'
import * as DateTime from '../../utils/DateTime'

// components
import {
  Grid,  
  Form,
  Checkbox,
  TextArea,
  Button,
} from 'semantic-ui-react'
import Block from './../../components/Block'
import Header from './../../components/Header'
import Footer from './../../components/Footer'
import PreparingMessage from "./PreparingMessage"

import "./Organized.scss"

class Organized extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      /* is form ready to be submitted - all conditions selected*/
      isReady: false,

      isSubmitted: false,

      /* form values */
      conditionSmartphone: props.adventure.IsHomeDelivery || props.adventure.IsOnlineEvent,
      conditionDatetime: false,

      comments: null,

      date: null,
      time: null,

      showDateChange: false
    }

    // bind event handlers
    this.onChange = this.onChange.bind(this)
    this.onToggleShowDateChange = this.onToggleShowDateChange.bind(this)
  }

  render() {
    const adventure = this.props.adventure;

    let conditionsInfo = null
    let conditions = null
    if(adventure.Conditions && adventure.Conditions.length) {
      if(adventure.IsHomeDelivery || adventure.IsOnlineEvent) {
        conditionsInfo = <Grid.Row><p>Bitte beachte und bestätige folgende Punkte:</p></Grid.Row>
      }
      else {
        conditionsInfo = <Grid.Row><p>Du siehst nun mögliche Anforderungen oder ob du etwas Spezielles mitnehmen oder anziehen musst. Bestätige die einzelnen Punkte oder lasse offen, falls etwas nicht möglich ist. Dann kümmern wir uns um die Lösung.</p></Grid.Row>
      }
      conditions = adventure.Conditions.map((c, i) => {
        return (<Grid.Row key={i}>
          <Checkbox label={c.Text} checked={this.state['condition' + i]} name={'condition' + i} onChange={this.onChange} />
        </Grid.Row>)
      })
    }
    
    let dateAndTime 
    if(adventure.IsHomeDelivery || adventure.IsOnlineEvent) {
      dateAndTime = `Wir überraschen dich am ${DateTime.ToShortDate(adventure.StartTime)} um ${DateTime.ToShortTime(adventure.StartTime)} Uhr an folgendem Ort: ${adventure.UserStartLocationText}`
    }
    else {
      dateAndTime = `Meine Überraschung startet am ${DateTime.ToShortDate(adventure.StartTime)} und dauert von ${DateTime.ToShortTime(adventure.StartTime)} Uhr bis ungefähr ${DateTime.ToShortTime(adventure.EndTime)} Uhr. Los geht's an folgender Adresse: ${adventure.UserStartLocationText}`
    }
    
    let smartphone = null
    if(!adventure.IsHomeDelivery && !adventure.IsOnlineEvent) {
      smartphone = (
        <Grid.Row>
          <p>Damit wir dich durch deine Überraschung führen können, brauchst du ein Smartphone und mobilen Datenempfang.</p>
          <p>
            <Checkbox label='Ich habe ein Smartphone oder ein anderes Gerät, um unterwegs meine Überraschungs-Seite aufzurufen' checked={this.state.conditionSmartphone} name="conditionSmartphone" onChange={this.onChange} />
          </p>
        </Grid.Row>
      )
    }

    let content =  (
      <Block id="surprise-organized" key="content" isMain={true}>
        <PreparingMessage isHomeDelivery={adventure.IsHomeDelivery} isOnlineEvent={adventure.IsOnlineEvent} />
        <h1>Deine Überraschung ist fast bereit!</h1>
        <Grid padded columns='equal'>
          {conditionsInfo}
          {conditions}
          {smartphone}
          <Grid.Row>
            <p>Kontrolliere zum Schluss noch den Termin. Möglicherweise hat die Startzeit aus organisatorischen Gründen leicht geändert.</p>
            <p>
              <Checkbox label={dateAndTime} checked={this.state.conditionDatetime} name="conditionDatetime" onChange={this.onChange} />
            </p>
          </Grid.Row>
          <Grid.Row>
            <p>Willst du uns sonst noch etwas sagen?</p>
          </Grid.Row>
          <Grid.Row>
            <Form style={{ width: '100%' }}>
              <TextArea fluid autoHeight placeholder='' name='comments' value={this.state.comments} onChange={this.onChange} />
            </Form>
          </Grid.Row>
        </Grid>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column floated="left">
              <Button onClick={this.reject} color="grey" disabled={this.state.isReady}>
                {adventure.IsHomeDelivery || adventure.IsOnlineEvent ? "Nicht alle Punkte erfüllt" : "Nicht alle Bedingungen erfüllt"}</Button>
            </Grid.Column>
            <Grid.Column floated="right">
              <Button floated="right" onClick={this.accept} disabled={!this.state.isReady} className='acceptButton'>Alles klar!</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* TODO: If clearly not used anymore, delete following code and related functions / code  */}
        {/* <Accordion style={{ marginTop:'40px'}}>
          <Accordion.Title active={this.state.showDateChange} index={0} onClick={this.onToggleShowDateChange}>
            <Icon name='dropdown' />
            Ich möchte den Termin ändern
        </Accordion.Title>
          <Accordion.Content active={this.state.showDateChange}>
            <h2>Terminänderung</h2>
            <Grid columns='equal'>
              <Grid.Row>
                <Grid.Column>
                  <p>Der Termin passt mir doch nicht. Ich wünsche deshalb folgenden Termin:</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <p>
                    {`${adventure.IsHomeDelivery || adventure.IsOnlineEvent ? "An welchem Tag sollen wir dich überraschen?" : "An welchem Tag soll deine Überraschung stattfinden?"} Wähle hier ein neues Datum.`}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <DateInput id='date' name='date' placeholder='Datum' value={this.state.date} offsetDays={config.constants.DAYS_TO_PREPARE} onChange={this.onChange} />
                  {this.state.isSubmitted && !this.state.date && <p className="error">Wann soll die Überraschung stattfinden? (Losgehen kann es frühestens in 5 Tagen)</p>}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <p>
                    {`${adventure.IsHomeDelivery || adventure.IsOnlineEvent ? "Um welche Zeit sollen wir dich überraschen?":"Wann soll deine Überraschung beginnen?"} Wähle hier eine Zeit.`}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <TimeInput name='time' placeholder='Zeit' value={this.state.time} onChange={this.onChange} />
                  {this.state.isSubmitted && !this.state.time && <p className="error">Wähle die ungefähre Startzeit</p>}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column floated="right">
                  <Button floated="right" onClick={this.reschedule}>Termin ändern</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>
        </Accordion> */}

      </Block>
    )

    return [
      <Header audience="adventurer" fixed={false} key="header"/>,
      content,
      <Footer key="footer" />
    ]
  }

  onChange = (e, { name, value, checked }) => {
    var change = {}

    if (value !== undefined) {
      change[name] = value
    } else {
      change[name] = checked
    }

    this.setState(change, () => {
      this.updateIsReady();
    })
  }

  onToggleShowDateChange = () => {
    this.setState(prevState => { this.setState({ showDateChange: !prevState.showDateChange }) })
  }

  updateIsReady() {
    let ready = this.state.conditionDatetime && this.state.conditionSmartphone;
    if (ready) {
      for (var i = 0; i < this.props.adventure.Conditions.length; i++) {
        if (!this.state["condition" + i]) {
          ready = false;
          break;
        }
      }
    }
    this.setState({ isReady: ready });
  }

  /**
   * Executes accept logic which is defined in SurprisePage
   */
  accept = () => {
    this.props.onAccept({ comments: this.state.comments })
  }

  /**
   * Executes reject logic which is defined in SurprisePage
   */
  reject = () => {
    let rejectedConditions = ''
    if (!this.state.conditionDatetime) rejectedConditions += 'Datum/Zeit,'
    if (!this.state.conditionSmartphone) rejectedConditions += 'Smartphone,'
    for (var i = 0; i < this.props.adventure.Conditions.length; i++) {
      if (!this.state["condition" + i]) {
        rejectedConditions += this.props.adventure.Conditions[i].Text + ","
      }
    }

    this.props.onReject({ comments: this.state.comments, rejectedConditions })
  }

  /**
   * Executes reschedule logic which is defined in SurprisePage
   */
  reschedule = () => {
    this.setState({ isSubmitted: true });
    if (this.state.time && this.state.date) {
      // TODO this is an ugly hack because we get the date from the controls in near useless format
      let date_parts = this.state.date.split('.')
      let start = new Date()
      start.setDate(Number(date_parts[0]))
      start.setMonth(Number(date_parts[1])-1)
      start.setFullYear(Number(date_parts[2]))
      start.setHours(Number(this.state.time) / 60)
      start.setMinutes(0)
      start.setSeconds(0)
      start.setMilliseconds(0)

      this.props.onReschedule({ start:start.toISOString() })
    }
  }
}

Organized.propTypes = {
  adventure:PropTypes.object.isRequired,
  onAccept:PropTypes.func.isRequired,
  onReject:PropTypes.func.isRequired,
  onReschedule:PropTypes.func.isRequired
}

export default Organized