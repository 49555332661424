import React from 'react';
import PropTypes from 'prop-types'

// components
import ContactBlock from './components/ContactBlock'
import Block from './../../components/Block'
import Header from './../../components/Header'
import Footer from './../../components/Footer'


class Unknown extends React.Component {


  render() {
    const adventure = this.props.adventure;

    let content = (
      <Block id="surprise-unknown" key="content" isMain={true}>
        <h1>Hallo {adventure.RecieverRealFirstName || adventure.RecieverName || ''}</h1>
        <p>
          Mit dieser Überraschung scheint leider etwas nicht in Ordnung zu sein - sie befindet sich in einem Status, der mir nicht bekannt ist.
        </p>

        <ContactBlock adventure={adventure} />

      </Block>
    )

    return [
      <Header audience="adventurer" fixed={false} key="header"/>,
      content,
      <Footer key="footer" />
    ]

  }
}

Unknown.propTypes = {
  adventure:PropTypes.object.isRequired
}

export default Unknown