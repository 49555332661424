import React from "react"
import {Grid} from 'semantic-ui-react'

function AvailableAreas({adventure}) {
  
  // gather items
  const items = (adventure.AvailableAreas || []).map(s => s).filter(s => (s||"").trim().length > 0)

  // no items? no need to render anything
  if(items.length === 0) {
    return null
  }

  // render
  const lis = items.map((text, index) => <li key={index}>{text}</li>)
  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <div>Beachte bei der Wahl des Ortes folgendes:</div>
        <ul>{lis}</ul>
      </Grid.Column>
    </Grid.Row>
  )
}

export default AvailableAreas