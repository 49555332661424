import React from "react"
import PropTypes from "prop-types"

import Block from "./Block"

import "./Error.scss"

class Error extends React.PureComponent {
  render() {
    return (
      <Block className="error">
        <div className="title">{this.props.title}</div>
        <div className="message">{this.props.message}</div>
      </Block>
    )
  }
}

Error.propTypes = {
  title:PropTypes.string.isRequired,
  message:PropTypes.string.isRequired
}

export default Error