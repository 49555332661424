import React from "react"

import "./Progress.scss"

function Progress({current, max}) {
  if(isNaN(current) || isNaN(max)) {
    return null
  }
  if(current < 0) {
    return null
  }
  let parts = []
  let width = Math.floor(100 / max)
  
  for(let i=0; i<max-1; i++) {
    let dividers = []
    dividers.push(
      <span className={`divider common ${i <= current ? 'active' : 'inactive'}`}></span>
    )
    if(i === (max-2)) {
      dividers.push(
        <span className={`divider last ${i === (current - 1) ? 'active' : 'inactive'}`}></span>
      )
    }
    parts.push(
      <span style={{width:`${width}%`}} key={`progress-part-${i}`} className={`part ${i < current ? 'active' : 'inactive'}`}>
        {dividers}
      </span>
    )
  }
  
  
  return (
    <div className="appentura-progress">
      {parts}
    </div>
  )
}

export default Progress