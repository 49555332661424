import React from 'react';
import PropTypes from 'prop-types'
import zenscroll from "zenscroll"
import * as DateTime from '../../utils/DateTime'
//import devlog from './../../utils/devlog'
//import loadImage from 'blueimp-load-image'
import {getBase64Strings} from "exif-rotate-js";

// components
import Map from './../../components/Map'
import TooEarly from './components/TooEarly'
import Header from './../../components/Header'
import Footer from './../../components/Footer'
import Timeline from './../../components/Timeline'
import ModalSelfie from "./../../components/ModalSelfie"
import ModalError from "./../../components/ModalError"
import ModalSelfieDone from "./../../components/ModalSelfieDone"
import Progress from "./../../components/Progress"

// styling and images
import images from "./../../images"
import "./Ready.scss"
import config from "../../config";
import superagent from "superagent";

import {AutoFontSize} from 'auto-fontsize'


// TODO state.isPosting ... handle this ...


class Ready extends React.Component {
  constructor(props) {
    super(props);

    let gracePeriodInMinutes = 0 // if the user arrives this many minutes early he will still be granted access

    this.state = {
      adventure: props.adventure, // TODO not used ... I think
      instructions_minimized:false, // when true, only the title of the instructions is displayed
      secondary_maximized:false,    // when true, the timeline (and footer) are displayed
      tooEarly: !(DateTime.Parse(props.adventure.StartTime).add(-1 * gracePeriodInMinutes, 'minutes') < new Date()),
      modalToShow:null,
      selfie_data:null,
      show_timeline_notification:false
    }

    // 'constants' used to place some ui elements
    this.HEIGHT_HEADER = 60  // Needs to conform to $header-height in Variables.scss!

    // references to document elements
    this.ref_scroll_sensor = null // we use this to recognize when to show minimized instructions header (div#timeline-and-footer)
    this.ref_secondary_header = null  // so we can make it swipeable
    this.ref_instructions_minimized = null  // so we can make it swipeable
  }

  componentDidMount() {
    // attach scroll event handler
    if(window.document.addEventListener)
      window.document.addEventListener("scroll", this.onDocumentScroll)
    else
      window.document.attachEvent("onscroll", this.onDocumentScroll)

    // make minimized instructions and secondary header swipeable
    // TODO can we re-instate the following?
    /*
    if(this.ref_secondary_header) {
      this.makeSwipable(
        this.ref_secondary_header,
        {
          onSwipeUp:this.onSecondary_swipeUp,
          onSwipeDown:this.onSwipeDown,
          onTap:this.onSecondary_click
        }
      )
    }
    if(this.ref_instructions_minimized) {
      this.makeSwipable(
        this.ref_instructions_minimized,
        {
          onSwipeDown:this.onSwipeDown,
          onTap:this.onInstructionsMinimized_click,
          onSwipeUp:()=>{}
        }
      )
    }
    */
  }

  componentWillUnmount() {
    // detach scroll event handler
    if(window.document.removeEventListener)
      window.document.removeEventListener("scroll", this.onDocumentScroll)
    else
      window.document.detachEvent("onscroll", this.onDocumentScroll)
  }

  render() {

    // TODO loader overlay when loading: {this.props.isLoading ? <LoaderOverlay/> : null}
    const adventure = this.props.adventure

    if(this.state.tooEarly) {
      // the adventurer is too early ... render the countdown instead of instructions and timeline
      return [
        <Header audience="adventurer" fixed={false} key="header"/>,
        <TooEarly adventure={adventure} title={"Hey, du bist zu früh dran ;)"} onCountdownOver={this.onCountdownOver} key="tooearly"/>,
        <Footer key="footer" />
      ]
    }
    else {
      return ([
        <Header audience="adventurer" fixed={true} key="header"/>,
        this.render_instructions_minimized(),
        this.render_instructions(),
        this.render_secondary(),
        this.render_modals()
      ])
    }
  }

  render_modals() {
    let modal_selfie = null
    let modal_selfie_done = null
    let modal_error = null
    if(this.state.selfie_data) {
      if(this.state.selfie_data_valid) {
        modal_selfie = (
          <ModalSelfie key="modal-selfie"
                       base64ImageData={this.state.selfie_data}
                       onCancel={this.onModalSelfieCancel}
                       onAccept={this.onModalSelfieAccept}/>
        )
      }
      else {
        modal_error = (
          <ModalError key="selfie-error"
                      title="Fehlerhaftes Bild"
                      message="Irgendetwas scheint mit diesem Selfie nicht zu stimmen. Bist du sicher, dass es sich um ein Foto handelt?"
                      onClose={this.onModalErrorClose}
                      data={{error:"invalid_image"}}
          />
        )
      }
    }

    if(this.state.modalToShow === "selfie-done") {
      modal_selfie_done = (
        <ModalSelfieDone key="modal-selfie-done" onClose={this.onModalSelfieDoneClose}/>
      )
    }
    return [modal_error, modal_selfie, modal_selfie_done]
  }

  render_instructions() {
    let info = this.getStepInfo()

    let button_previous = info.isFirst ? <div/> : <button data-direction="previous" onClick={this.onNavigate}>zurück</button>
    let button_next = info.isLast ? <div /> : <button data-direction="next" onClick={this.onNavigate}>weiter</button>

    let image = null
    if(info.imageUrl) {
      image = (
        <div id="instructions-image" className="block">
          <div className="image-container">
            <img src={info.imageUrl} alt={info.title}/>
            {info.imageText ? 
            <div className='image-overlay'>
            <AutoFontSize
              text={info.imageText}
              targetLines={1}
              fontSizeMapping={[
                { fontSize: 30, lineHeight: '30px' },
                { fontSize: 40, lineHeight: '40px' },
                { fontSize: 50, lineHeight: '50px' },
                { fontSize: 60, lineHeight: '60px' }
              ]}
              className="image-overlay"
            /></div> : null}
          </div>
        </div>
      )
    }

    let map = null
    if(info.hasMap) {
      map = (
        <div id="instructions-map" className="block">
          <div className="block-inner">
            <Map adventure={info.adventure} step={info.step} />
          </div>
        </div>
      )
    }

    let attachments = null
    if(info.hasAttachments) {
      attachments = (
        <div id="instructions-attachments" className="block">
          <div className="block-inner">
            <ul>
              {info.attachments.map((attachment, index) => <li key={index}><a href={attachment.Url} target="_blank" rel="noopener noreferrer">{attachment.Title}</a></li>)}
            </ul>
          </div>
        </div>
      )
    }

    let links = null
    if(info.hasLinks) {
      links = (
        <div id="instructions-attachments" className="block">
          <div className="block-inner">
            <ul>
              {info.links.map((link, index) => <li key={index}><a href={link.Url} target="_blank" rel="noopener noreferrer">{link.Title}</a></li>)}
            </ul>
          </div>
        </div>
      )
    }

    return (
      <div id="instructions" key="instructions">
        <div id="instructions-progress" className="block">
          <div className="block-inner">
            <Progress current={info.adventure.CurrentStep - 1} max={info.adventure.Steps.length} />
          </div>
        </div>
        <div id="instructions-title" className="block">
          <div className="title block-inner">{info.title}</div>
        </div>
        <div ref={(div) => {this.ref_scroll_sensor = div}} />
        <div id="instructions-text" className="block">
          <div className="block-inner">
            {
              info.intro.map((item, outer_index) => {
                if(Array.isArray(item)) {
                  let items = item.map((text, inner_index) => <li key={inner_index}>{text}</li>)
                  return <ul key={outer_index}>{items}</ul>
                }
                else {
                  return <p key={outer_index}>{item}</p>
                }
              })
            }
            <ul>
              {info.instructions.map((text, index) => <li key={index} dangerouslySetInnerHTML={{__html:text}} />)}
            </ul>
          </div>
        </div>
        {attachments}
        {links}
        <div id="instructions-navigation" className="block">
          <div className="block-inner">
            <div className="buttons">
              {button_previous}
              {button_next}
            </div>
          </div>
        </div>
        {image}
        {map}
      </div>
    )
  }

  render_instructions_minimized() {
    let className_visible = this.state.instructions_minimized ? "visible" : "hidden"
    if(this.state.secondary_maximized) {
      className_visible = "visible"
    }
    return (
      <div id="instructions-minimized" key="instructions-minimized" className={`block ${className_visible}`} onClick={this.onInstructionsMinimized_click} ref={(div) => this.ref_instructions_minimized = div}>
        <div className="block-inner title">
          zurück zu den Anweisungen
        </div>
      </div>
    )
  }

  render_secondary() {
    const adventure = this.props.adventure
    let liveId = adventure.LiveId || 'unknown' // TODO 'unknown' ... not the clearest way to handle this
    let surpriseId = adventure._id

    let className = this.state.secondary_maximized ? "maximized" : "minimized"

    let notification_badge = this.state.show_timeline_notification ? <span className="notification-badge"><img src={images.misc.bell} alt="notification"/></span> : null
    return (
      <div id="secondary" key="secondary" className={className}>
        <div id="secondary-chevron" onClick={this.onSecondary_click}>
          <div id="secondary-chevron-inner">
            <img src={images.misc.chevron_circle} alt="toggle" className={className} />
          </div>
        </div>
        <div id="secondary-header" className="block"  ref={(div) => this.ref_secondary_header=div}>
          <div className="block-inner">
            <div className="button empty" onClick={this.onSecondary_click}></div>
            <div className="heading" onClick={this.onSecondary_click}>
              <span>
                Deine Timeline
                {notification_badge}
              </span>
            </div>
            <div className="button camera">
              <input ref="selfie" type="file" accept="image/*;capture=camera" id="cameraInput" name="cameraInput" onChange={this.onSelectImage} className="inputfile" />
              <label htmlFor="cameraInput">
                <img src={images.actions.selfie} alt="click here to create visible memories" />
              </label>
            </div>
          </div>
        </div>
        <div id="secondary-content">

          <div className="block" style={{backgroundColor:"#ebf3e9", paddingTop: 10}}>
            <div className="block-inner">
              <div id="secondary-explain">
                Diese Zusammenfassung deiner Überraschung siehst nur du, solange du sie nicht geteilt hast.
              </div>
            </div>
          </div>

          <Timeline liveId={liveId}
                    surprise={adventure}
                    surpriseId={surpriseId}
                    audience="adventurer"
                    onEventAdded={this.onTimelineEventAdded}
                    onEventRemoved={this.onTimelineEventRemoved}
          />
          <Footer/>
        </div>
      </div>
    )
  }

  onCountdownOver = () => {
    // we reload the whole page so that the ticker events are fired server-side
    window.location.reload()
  }

  onDocumentScroll = (e) => {
    // the following was used to display the minimized header when scrolling ... since we no longer display the step's title but a generic 'back to instructions' message, display the minimzed header when scrolling makes no longer sense.
    // I leave this bit of code in here for now ... people tend to change their mind ...
    // TODO #cleanup if this whole scrolling functionality is really no longer used, remove this and anything related
    /*
    if(this.ref_scroll_sensor) {
      let top = this.ref_scroll_sensor.getBoundingClientRect().top
      if(top < (this.HEIGHT_HEADER) && !this.state.instructions_minimized) {
        this.setState({...this.state, instructions_minimized:true})
      }
      if(top >= (this.HEIGHT_HEADER) && this.state.instructions_minimized) {
        this.setState({...this.state, instructions_minimized:false})
      }
    }
    */
  }

  onInstructionsMinimized_click = (e) => {
    this.resetUi()
  }

  onSwipeDown = () => {
    this.resetUi()
  }

  onSecondary_click = (e) => {
    this.toggleSecondary()
  }

  onSecondary_swipeUp = () => {
    if(!this.state.secondary_maximized) {
      this.setState({...this.state, secondary_maximized:true})
    }
  }

  onNavigate = (e) => {
    e.preventDefault()
    let direction = e.target.dataset.direction
    if(direction === "previous") {
      this.props.onPrevious()
    }
    else {
      this.props.onNext()
    }
  }

  /*

  onSelectImage_v1 = () => {
    // read file content and send to server
    const file = this.refs.selfie.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      // get base64 data
      const base64img = reader.result

      // validate the image buffer
      let buffer_parts = base64img.split(';')
      let buffer_header = (buffer_parts[0] || '').toLowerCase()
      let selfie_data_valid =  buffer_header.startsWith('data:image')

      // update state
      this.setState({...this.state, selfie_data:base64img, selfie_data_valid})
    }, false)

    if (file) {
      devlog('Timeline.onSelectImage() - got a file:', file)
      reader.readAsDataURL(file);
    }
  }

  onSelectImage_v2 = () => {
    // get the file from our <input type='file'>
    const file = this.refs.selfie.files[0];

    let cb = (image, meta) => {
      let orientation = 1
      if(meta.exif) {
        orientation = Number(meta.exif.get('Orientation') || 1)
        devlog('image orientation:', orientation)
        //alert('iamge orientation ' + orientation)
      }
      else {
        //alert("no meta.exif");
      }

      // create a canvas
      let canvas = document.createElement('canvas')

      // change canvas' dimension if necessary
      switch(orientation) {
        case 5:
        case 6:
        case 7:
        case 8:
          // these orientations have the image lying on its side, hence we need to change switch canvas height and width
          canvas.width = image.height
          canvas.height = image.width
          break
        default:
          canvas.width = image.width
          canvas.height = image.height
          break
      }

      // define rotation, translation, scale
      let rotation = 0
      let translate = {x:0, y:0}
      let scale = {x:1, y:1}

      switch(orientation) {
        case 2:
          translate.x = image.width
          scale.x = -1
          break
        case 3:
          translate.x = -1 * image.width
          translate.y = -1 * image.height
          rotation = Math.PI
          break
        case 4:
          translate.y = image.height
          scale.y = -1
          break
        case 5:
          rotation = 0.5 * Math.PI
          scale.y = -1
          break
        case 6:
          rotation = 0.5 *Math.PI
          translate.y = -1 * image.height
          break
        case 7:
          rotation = 0.5 * Math.PI
          translate.x = image.width
          translate.y = -1 * image.height
          scale.x = -1
          break
        case 8:
          rotation = -0.5 * Math.PI
          translate.x = -1 * image.width
          break
        default:
          break
      }

      // get 2d context and apply rotation and translation
      let ctx = canvas.getContext("2d")
      ctx.save()
      ctx.rotate(rotation)
      ctx.translate(translate.x, translate.y)
      ctx.scale(scale.x, scale.y)
      ctx.drawImage(image, 0, 0, image.width, image.height)
      ctx.restore()

      document.body.appendChild(canvas)

      // validate the image buffer
      let base64img = canvas.toDataURL("image/jpeg");
      let buffer_parts = base64img.split(';')
      let buffer_header = (buffer_parts[0] || '').toLowerCase()
      let selfie_data_valid =  buffer_header.startsWith('data:image')

      // update state
      this.setState({...this.state, selfie_data:base64img, selfie_data_valid})
    }

    let options = {
      meta:true,
      canvas:false
    }

    loadImage(file, cb, options)
  }

  onSelectImage_v3 = () => {
    // get the file from our <input type='file'>
    const file = this.refs.selfie.files[0];

    let cb = (image, meta) => {
      // get the exif orientation
      let orientation = 1
      if(meta.exif) {
        orientation = Number(meta.exif.get('Orientation') || 1)
        devlog('image orientation:', orientation)
        alert("orientation: " + orientation)
      }
      else {
        alert("no meta.exif")
      }

      // create a canvas and context
      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext("2d");

      // set canvas dimensions
      if (4 < orientation && orientation < 9) {
        canvas.width = image.height;
        canvas.height = image.width;
      } else {
        canvas.width = image.width;
        canvas.height = image.height;
      }

      // transform context before drawing image
      switch (orientation) {
        case 2: ctx.transform(-1, 0, 0, 1, image.width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, image.width, image.height); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, image.height); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, image.height, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, image.height, image.width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, image.width); break;
        default: break;
      }

      // draw the image
      ctx.drawImage(image, 0, 0);
      
      // validate the image buffer
      let base64img = canvas.toDataURL("image/jpeg");
      let buffer_parts = base64img.split(';')
      let buffer_header = (buffer_parts[0] || '').toLowerCase()
      let selfie_data_valid =  buffer_header.startsWith('data:image')

      // update state
      this.setState({...this.state, selfie_data:base64img, selfie_data_valid})
    }

    let options = {
      meta:true,
      canvas:false
    }

    loadImage(file, cb, options);
  }

  */

  onSelectImage = async() => {
    const files = this.refs.selfie.files;
    const data = await getBase64Strings(files, {maxSize: 1024});
    this.setState({...this.state, selfie_data:data[0], selfie_data_valid:true});
  }

  onModalSelfieCancel = () => {
    this.setState({...this.state, selfie_data:null})
  }

  onModalSelfieAccept = (info) => {
    let marketingAllowed = info.marketingAllowed
    this.postSelfie(this.state.selfie_data, marketingAllowed)
    this.setState({...this.state, selfie_data:null, modalToShow:"selfie-done"})
  }

  onModalErrorClose = (data) => {
    if(data.error === "invalid_image") {
      this.setState({...this.state, selfie_data_valid:false, selfie_data:null})
    }
  }

  onModalSelfieDoneClose = (e) => {
    if(e.showTimeline) {
      this.setState({...this.state, modalToShow:null, secondary_maximized:true})
    }
    else {
      this.setState({...this.state, modalToShow:null})
    }

  }

  onTimelineEventAdded = () => {
    if(this.state.secondary_maximized === false) {
      this.setState({...this.state, show_timeline_notification:true})
    }
  }

  postSelfie(base64img, marketingAllowed) {
    // The selfie route expects data like
    // * surpriseId: id of the surprise
    // * surpriseStep: step during which the selfie was taken
    // * liveId: liveId of the surprise (Surprise.LiveId)
    // * data: base64 image data
    // * marketingAllowed: boolean indicating if marketing is allowed

    let data = {
      data:base64img,
      surpriseId:this.props.adventure._id,
      surpriseStep:(this.props.adventure.CurrentStep || 1) - 1,
      liveId:this.props.adventure.LiveId,
      marketingAllowed:marketingAllowed
    }

    this.setState({...this.state, isPosting:true})

    superagent
      .post(`${config.api.root}/surprise/selfie`)
      .send(data)
      .end((err, res) => {
        if(err) {
          // TODO error handling
          this.setState({...this.state, isPosting:false})
        }
        else {
          this.setState({...this.state, isPosting:false})
        }
      })
  }

  /*
  validateImageBuffer(buffer) {
    let parts = buffer.split(';')
    let header = (parts[0] || '').toLowerCase()
    return header.startsWith('data:image')
  }
  */

  resetUi() {
    this.setState({...this.state, secondary_maximized:false, instructions_minimized:false})
    zenscroll.toY(0, 500) // second parameter is time in miliseconds
  }

  toggleSecondary() {
    let isMaximized = this.state.secondary_maximized
    if(isMaximized) {
      this.resetUi()
    }
    else {
      this.setState({...this.state, secondary_maximized:true, show_timeline_notification:false})
    }
  }

  makeSwipable(element, controller) {

    let onTouchStart = (e) => {
      let touchobj = e.changedTouches[0]

      controller.startX = touchobj.pageX
      controller.startY = touchobj.pageY
      controller.startTime = new Date().getTime() // record time when finger first makes contact with surface

      e.preventDefault()
    }

    let onTouchMove = (e) => {
      e.preventDefault() // prevent scrolling when inside DIV
    }

    let onTouchEnd = (e) => {
      let touchobj = e.changedTouches[0]
      // let distX = touchobj.pageX - controller.startX // get horizontal dist traveled by finger while in contact with surface
      let distY = touchobj.pageY - controller.startY // get vertical dist traveled by finger while in contact with surface
      // let elapsedTime = new Date().getTime() - controller.startTime // get time elapsed
      if(distY > 10) {
        controller.onSwipeDown()
      }
      else if(distY < -10) {
        controller.onSwipeUp()
      }
      else {
        // this is akin to a click
        controller.onTap()
      }
      e.preventDefault()
    }

    element.addEventListener("touchstart", onTouchStart, false)
    element.addEventListener("touchmove", onTouchMove, false)
    element.addEventListener("touchend", onTouchEnd, false)
  }


  getStepInfo() {
    const adventure = this.props.adventure

    const step = adventure.Steps[adventure.CurrentStep - 1]

    let info = {}


    if(adventure.CurrentStep === 0) {
      // set the title
      info.title = `Hallo ${adventure.RecieverRealFirstName || adventure.RecieverName || ''}`

      // the text(s)
      // TODO in some ways this should be in configuration
      let welcome = "Willkommen zu deiner Appentura Überraschung."
      let hints = []
      if(!adventure.IsHomeDelivery && !adventure.IsOnlineEvent) {
        hints.push("Folge bitte den Instruktionen genau, damit du sicher nichts verpasst ;)")
        hints.push("Mach zwischendurch zur Erinnerung ein Selfie - ganz einfach mit dem Foto-Icon unten rechts")
      }
      else {
        hints.push("Mach zur Erinnerung ein Selfie - ganz einfach mit dem Foto-Icon unten rechts :)")
      }
      let action = adventure.IsHomeDelivery || adventure.IsOnlineEvent ? "Wähle 'weiter' um mehr zu erfahren." : "Nun geht’s los! Wähle 'weiter' um deine Überraschung zu starten."
      
      info.intro = [ welcome, hints, action]
      info.instructions = []
      info.isFirst = true
      info.imageUrl = null
      info.imageText = null
      info.hasMap = false
      info.step = null
      info.hasAttachments = false
      info.hasLinks = false
    }
    else {
      info.title = step.Title
      info.intro = []
      info.instructions = step.Instructions.map(text => text)
      info.isFirst = false
      info.imageUrl = step.ImageUrl || null
      info.imageText = step.ImageText || null
      info.hasMap = step.StartCoordinates && step.EndCoordinates
      info.step = step
      info.hasAttachments = (step.Attachments || []).length > 0
      info.attachments = (step.Attachments || [])
      info.links = (step.Links || [])
      info.hasLinks = info.links.length > 0
      
    }

    info.isLast = adventure.CurrentStep >= adventure.Steps.length
    info.adventure = adventure


    return info
  }
}

Ready.propTypes = {
  adventure:PropTypes.object.isRequired,
  onNext:PropTypes.func.isRequired,
  onPrevious:PropTypes.func.isRequired,
  isLoading:PropTypes.bool.isRequired
}

Ready.defaultProps = {
  isLoading:false
}

export default Ready