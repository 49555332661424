import React from 'react';

class ContactBlock extends React.Component {

  render() {

    const adventure = this.props.adventure;

    return (
      <div>
        <p>
          Kontaktiere uns unter
          {' '}<a href="mailto:hello@appentura.ch">hello@appentura.ch</a>{' '}
          oder
          {' '}<a href="tel:+41315112132">+41 31 511 21 32</a>{' '}
          mit Angabe des Codes <b>{adventure._id.substr(adventure._id.length - 4)}</b>,
          damit wir die Angelegenheit klären können und du deine Überraschung bald erleben kannst.
        </p>
        <p>
          Merci für Dein Verständnis.
        </p>
        <p>
          Dein Team Appentura
        </p>
      </div>
    );

  }
}

export default ContactBlock;