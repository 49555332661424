import React from "react"
import PropTypes from "prop-types"
import config from "./../config"

import Block from './Block'

import "./MemoryLane.scss"

class MemoryLane extends React.Component {
  render() {
    if(this.props.liveId) {
      let tickerUrl = `${config.site.ticker}/${this.props.liveId}`
      return (
        <Block isMain={false} id="memory-lane">

          <p style={{marginBottom: 20}}>
            Dir hat deine Überraschung gefallen und du schwelgst gerne in Erinnerungen? Dann solltest du unbedingt noch mal deine
          </p>
          <a href={tickerUrl} target="_blank" rel="noopener noreferrer">Timeline anschauen</a>

        </Block>
      )
    }
    else {
      return null
    }

  }
}

MemoryLane.propTypes = {
  liveId:PropTypes.string
}

export default MemoryLane

